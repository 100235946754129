import http from './../../../../services/vue-axios/axios'
import endpoints from './../../../../services/endpoints'
import TYPES from './../types'

export default {
	async getAuthUser({commit}, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.GET_AUTH_USER_ME)
				.then(res => {
					commit(TYPES.UPDATE_AUTH_USER, res.data.data)
					resolve(res)
				}).catch(err => {
				// console.log('lok:', err)
				reject(err)
			})
		})
	},
	async getDashboardMetrics({commit}, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.INHOUSE_KPI)
				.then(res => {
					resolve(res)
				}).catch(err => {
				reject(err)
			})
		})
	},
	async getCompanies({commit}, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.COMPANY_SETTINGS_ENDPOINT}${payload.data}`)
				.then(res => {
					commit(TYPES.SET_COMPANIES, res.data.data)
					resolve(res)
				}).catch(err => {
				reject(err)
			})
		})
	},
	async getBuyers({commit}, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.COMPANY_ENDCUSTOMERS}${payload.data}`)
				.then(res => {
					commit(TYPES.SET_BUYERS, res.data)
					resolve(res)
				}).catch(err => {
				reject(err)
			})
		})
	},

}
