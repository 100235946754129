import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {getCusToken} from './../services-jwt'
import {getBuyerId, getCurrentCompanyID} from "../storage-window";

Vue.use(VueAxios, axios)

const http = Vue.axios.create({
	baseURL: process.env.VUE_APP_BASE_URL_EXTERNAL,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	}
})


http.interceptors.request.use(
	function (req) {
		const token = `${getCusToken()}`
		if (token) {
			req.headers.common.Authorization = `Token ${token}`
			let companyID = getCurrentCompanyID()
			if (window.location.pathname.includes('/customer') || getBuyerId()) {
				if (getBuyerId()) {
					let buyerInfo = getBuyerId()

					if (buyerInfo.hasOwnProperty('buyer_id') && buyerInfo.hasOwnProperty('referrer')) {
						if (req.url.includes('?') === false) {
							if (req.url.includes('buyer_id') === false) {
								req.url += `?buyer_id=${buyerInfo['buyer_id']}`
							}
						} else {
							if (req.url.includes('buyer_id') === false) {
								req.url += `&buyer_id=${buyerInfo['buyer_id']}`
							}
						}
					}

				}
			}
			if (companyID) {
				if (req.url.includes('?') === false) {
					if (req.url.includes('company_id') === false) {
						req.url += `?company_id=${getCurrentCompanyID()}`
					}
				} else {
					if (req.url.includes('company_id') === false) {
						req.url += `&company_id=${getCurrentCompanyID()}`
					}
				}
			}
		}
		return req
	},
	function (err) {
		return Promise.reject(err)
	}
)


export default http

