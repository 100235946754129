import {
	CalculateGrossMargin,
	CalculateSalesPriceExVat,
	categoryFiltering,
	getShopOwnerTourGuide,
	saveCustomization,
	storeTourGuide, saveCompanyCustomizationSetting, saveSellerSetting
} from './../../../../services/storage-window'

export default {
	UPDATE_AUTH_USER(state, payload) {
		state.authUser = payload
	},
	UPDATE_USER_COMPANY(state, payload) {
		state.companyUser = payload
	},
	UPDATE_COMPANY_SETTING(state, payload) {
		saveSellerSetting(payload)
		if (payload.price_list === null) {
			state.companySetting = payload
			state.companySetting.price_list = {}
		} else {
			state.companySetting = payload
		}
	},
	UPDATE_COMPANY_FORM_HELP_TEXT(state, payload) {
		state.CompanyFormHelpText = payload
	},
	FILTER_SEARCH(state, payload) {
		let filterName = state.copyArticles.data.filter(item =>
			item.description.toLowerCase().includes(`${payload.value.toLowerCase()}`)
		)
		if (filterName.length < 0) {
			filterName = state.copyArticles.data.filter(item =>
				item.product_name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
			)
		}
		if (filterName.length < 0) {
			filterName = state.copyArticles.data.filter(item =>
				item.article_number.toLowerCase().includes(`${payload.value.toLowerCase()}`)
			)
		}
		state.articles.data = filterName
	},
	UPDATE_ALL_CUSTOMERS(state, payload) {
		state.allCustomers = payload
	},
	FILTER_ALL(state, _) {
		state.articles.data = state.copyArticles.data
	},
	FILTER_ARTICLE_WITH_IMAGE(state, payload) {
		let filterArticle = []
		if (payload.status === 'true') {
			filterArticle = state.copyArticles.data.filter(item => {
				return item.image.length > 0
			})
			state.articles.data = filterArticle
		} else {
			filterArticle = state.copyArticles.data.filter(item => {
				return item.image.length <= 0
			})
			state.articles.data = filterArticle
		}
	},
	FILTER_ARTICLE_BY_RELEVANCE(state, payload) {
		let filterArticle = []
		if (payload.status === 'true') {
			filterArticle = state.copyArticles.data.filter(item => {
				return item.is_relevant === true
			})
			state.articles.data = filterArticle
		} else {
			filterArticle = state.copyArticles.data.filter(item => {
				return item.is_relevant === false
			})
			state.articles.data = filterArticle
		}
	},
	UPDATE_USER_SUBCRIPTION_TYPE(state, payload) {
		state.subscriptionType = payload
	},
	UPDATE_USER_SUBCRIPTION(state, payload) {
		// console.log('PAYLOAD:', payload)
		state.userSubscription = {}
		state.userSubscription = JSON.parse(JSON.stringify(payload))
		// console.log('state:', state.userSubscription)
	},
	UPDATE_USER_BILLING(state, payload) {
		state.shopBilling = JSON.parse(JSON.stringify(payload))
		state.copyShopBilling = JSON.parse(JSON.stringify(payload))
	},
	UPDATE_ACTIVE_ACCOUNTS(state, payload) {
		state.activeAccounts = payload
	},
	UPDATE_USER_NOTIFICATION(state, payload) {
		state.userNotification = payload
	},
	UPDATE_PRICE_LIST(state, payload) {
		state.priceList = payload
	},
	UPDATE_COST_CENTER(state, payload) {
		state.costCenter = payload
	},
	UPDATE_CUSTOMER_CATEGORY(state, payload) {
		state.customerCategory = payload
	},
	UPDATE_SHOP_USERS(state, payload) {
		state.shopUsers = JSON.parse(JSON.stringify(payload))
		state.copyShopUsers = JSON.parse(JSON.stringify(payload))
	},
	UPDATE_APPLICATION_LOG(state, payload) {
		state.applicationLog = JSON.parse(JSON.stringify(payload))
		state.copyApplicationLog = JSON.parse(JSON.stringify(payload))
	},
	UPDATE_SHOP_CUSTOMERS(state, payload) {
		payload.data.forEach(element => {
			element.checked = false
		})
		state.shopCustomers = JSON.parse(JSON.stringify(payload))
		state.shopCustomersDrawer = JSON.parse(JSON.stringify(payload))
		state.copyShopCustomersDrawer = JSON.parse(JSON.stringify(payload))
		state.shopCustomersDrawerSetting = JSON.parse(JSON.stringify(payload))
		state.copyShopCustomersDrawerSetting = JSON.parse(JSON.stringify(payload))
		state.copyShopCustomers = JSON.parse(JSON.stringify(payload))
	},
	UPDATE_NEW_SHOP_CUSTOMERS(state, payload) {
		payload.data.forEach(element => {
			element.checked = false
		})
		state.shopCustomers.data = JSON.parse(JSON.stringify(payload.data))
		state.copyShopCustomers.data = JSON.parse(JSON.stringify(payload.data))
	},
	UPDATE_SHOP_CUSTOMERS_DRAWER(state, payload) {
		const copy = {...state.shopCustomersDrawer}
		payload.data.forEach(element => {
			copy.data.push(element)
		})
		copy.current_page = payload.current_page
		copy.total_page = payload.total_page
		copy.total_count = payload.total_count

		state.shopCustomersDrawer = JSON.parse(JSON.stringify(copy))
		state.copyShopCustomersDrawer = JSON.parse(JSON.stringify(copy))
	},
	UPDATE_SHOP_CUSTOMER_DRAWER_SETTING(state, payload) {
		const copy = {...state.shopCustomersDrawerSetting}
		payload.data.forEach(element => {
			copy.data.push(element)
		})
		copy.current_page = payload.current_page
		copy.total_page = payload.total_page
		copy.total_count = payload.total_count

		state.shopCustomersDrawerSetting = JSON.parse(JSON.stringify(copy))
		state.copyChopCustomersDrawerSetting = JSON.parse(JSON.stringify(copy))
	},
	TOGGLE_CUSTOMER_CHECKBOX(state, payload) {
		const customerIndex = state.shopCustomers.data.findIndex(el => el.id === payload.id)
		const customerArray = [...state.shopCustomers.data]
		customerArray[customerIndex] = {...customerArray[customerIndex], checked: payload.value}
		state.articles.data = customerArray
	},
	TOGGLE_ALL_CUSTOMER_CHECKBOX(state, payload) {
		state.shopCustomers.data.forEach(element => {
			element.checked = payload
		})
	},
	UPDATE_DASHBOARD_METRICS(state, payload) {
		state.dashboardMetrics = payload
	},
	UPDATE_ARTICLES(state, payload) {
		try {
			payload.data.forEach(element => {
				element.sales_price = !parseFloat(element.sales_price) ? 0 : element.sales_price
				const {marginPercentage} = CalculateGrossMargin(
					parseFloat(element.purchase_price),
					parseFloat(element.sales_price),
					element.vat_rate
				)
				element.checked = false
				if (element.description) {
					element.description = element.description.slice(0, 70)
				}
				element.margin = !parseInt(marginPercentage) ? 0 : marginPercentage
			})
		} catch (e) {}


		state.copyArticles = JSON.parse(JSON.stringify(payload))
		state.articles = JSON.parse(JSON.stringify(payload))
		state.finalArticleCopy = payload
	},
	UPDATE_SEARCH_ARTICLES(state, payload) {
		payload.data.forEach(element => {
			element.price = !parseFloat(element.price) ? 0 : element.price
			const {marginPercentage} = CalculateGrossMargin(
				parseFloat(element.purchase_price),
				parseFloat(element.price),
				element.vat_rate
			)
			element.checked = false
			element.description = element.description.slice(0, 70)
			element.margin = !parseInt(marginPercentage) ? 0 : marginPercentage
		})
		state.articles = JSON.parse(JSON.stringify(payload))
	},
	RESET_ARTICLES(state, _) {
		state.articles = state.copyArticles
	},
	ADVANCE_FILTER_ARTICLE_MARGIN(state, payload) {
		const filterMargin = state.copyArticles.data.filter(item => {
			if (payload.range === 'equal') {
				return parseInt(item.margin) === parseInt(payload.value)
			}
			if (payload.range === 'between') {
				return parseInt(item.margin) >= parseInt(payload.start) && parseInt(item.margin) <= parseInt(payload.end)
			}
			if (payload.range === 'greater') {
				return parseInt(item.margin) > parseInt(payload.value)
			}
			if (payload.range === 'less') {
				return parseInt(item.margin) < parseInt(payload.value)
			}
		})

		if (filterMargin.length > 0) {
			state.articles.data = filterMargin
		} else {
			state.articles.data = []
		}
		state.copyArticles = state.finalArticleCopy
	},
	UPDATE_MARGIN(state, payload) {
		const singleProduct = state.articles.data[payload.id]
		const productIndex = state.articles.data.findIndex(el => el.id === singleProduct.id)
		const productStateCopy = [...state.articles.data]

		const {marginPercentage} = CalculateGrossMargin(
			parseFloat(singleProduct.purchase_price),
			parseFloat(payload.salesPrice),
			singleProduct.vat_rate
		)

		productStateCopy[productIndex] = {
			...productStateCopy[productIndex],
			margin: marginPercentage,
			price: payload.salesPrice,
			sales_price: payload.salesPrice
		}
		state.articles.data = productStateCopy
	},
	UPDATE_SINGLE_MARGIN(state, payload) {
		const {marginPercentage} = CalculateGrossMargin(
			parseFloat(payload.purchase_price),
			parseFloat(payload.sales_price),
			payload.vat_rate
		)
		state.articles.data[payload.id].margin = marginPercentage

	},
	UPDATE_SALES_MARGIN(state, payload) {
		const singleProduct = state.articles.data[payload.id]
		const productIndex = state.articles.data.findIndex(el => el.id === singleProduct.id)
		const productStateCopy = [...state.articles.data]

		const {marginPercentage} = CalculateGrossMargin(
			parseFloat(payload.costPrice),
			parseFloat(singleProduct.sales_price),
			singleProduct.vat_rate
		)

		productStateCopy[productIndex] = {
			...productStateCopy[productIndex],
			margin: marginPercentage,
			purchase_price: payload.costPrice
		}
		state.articles.data = productStateCopy
	},
	UPDATE_SALESPRICE_EX_VAT(state, payload) {
		const singleProduct = state.articles.data[payload.id]
		const productIndex = state.articles.data.findIndex(el => el.id === singleProduct.id)
		const productStateCopy = [...state.articles.data]

		const {salesPriceExVat} = CalculateSalesPriceExVat(
			parseFloat(singleProduct.purchase_price),
			parseFloat(payload.margin),
			singleProduct.vat_rate
		)

		productStateCopy[productIndex] = {
			...productStateCopy[productIndex],
			margin: payload.margin,
			price: parseFloat(salesPriceExVat).toFixed(2)
		}
		state.articles.data = productStateCopy
	},
	UPDATE_SELECTED_ARTICLE(state, payload) {
		payload.articles.forEach(element => {
			const productIndex = state.articles.data.findIndex(el => el.id === element.article_id)
			const productStateCopy = [...state.articles.data]

			productStateCopy[productIndex].purchase_price = element.purchase_price
			productStateCopy[productIndex].price = element.price
			productStateCopy[productIndex].quantity_in_stock = element.quantity_in_stock
			productStateCopy[productIndex].checked = false

			const {marginPercentage} = CalculateGrossMargin(
				parseFloat(element.purchase_price),
				parseFloat(element.price),
				productStateCopy[productIndex].vat_rate
			)

			productStateCopy[productIndex] = {...productStateCopy[productIndex], margin: marginPercentage}
			state.articles.data = productStateCopy
		})
	},
	UPDATE_ARTICLE_QUANTITY(state, payload) {
		const articleIndex = state.articles.data.findIndex(el => el.id === payload.row)
		const articleArray = [...state.articles.data]
		articleArray[articleIndex] = {...articleArray[articleIndex], quantity_in_stock: payload.value}
		state.articles.data = articleArray
	},
	UPDATE_ARTICLES_SPREADSHEET(state, payload) {
		state.articleSpreadSheet = JSON.parse(JSON.stringify(payload))
		state.copyArticleSpreadSheet = JSON.parse(JSON.stringify(payload))
	},
	TOGGLE_CHECKBOX(state, payload) {
		const newArticleIndex = state.articles.data.findIndex(el => el.id === payload.id)
		const newArticleArray = [...state.articles.data]
		newArticleArray[newArticleIndex] = {...newArticleArray[newArticleIndex], checked: payload.value}
		state.articles.data = newArticleArray
	},
	TOGGLE_ALL_CHECKBOX(state, payload) {
		state.articles.data.forEach(element => {
			element.checked = payload
		})
	},
	UPDATE_ORDERS(state, payload) {
		state.orders = JSON.parse(JSON.stringify(payload))
		state.copyOrders = JSON.parse(JSON.stringify(payload))
	},
	UPDATE_INVOICE(state, payload) {
		state.invoice = JSON.parse(JSON.stringify(payload))
		state.copyInvoice = JSON.parse(JSON.stringify(payload))
	},
	UPDATE_PRODUCT_CATEGORY(state, payload) {
		payload.data.forEach(element => {
			element.checked = false
			// element.sub_category.forEach(el => {
			// 	el.checked = false
			// })
		})
		state.productCategory = payload
	},
	TOGGLE_ALL_CATEGORY_CHECKBOX(state, payload) {
		state.productCategory.data.forEach(element => {
			element.checked = payload
		})
	},
	UPDATE_CUSTOMIZATION(state, payload) {
		state.customization = payload
		saveCustomization(payload)
	},
	TOGGLE_CATEGORY_CHECKBOX(state, payload) {
		const newCategoryIndex = state.productCategory.data.findIndex(el => el.parent_category_id === payload.id)
		const newCategoryArray = [...state.productCategory.data]
		newCategoryArray[newCategoryIndex] = {...newCategoryArray[newCategoryIndex], checked: payload.value}
		state.productCategory.data = newCategoryArray
	},
	TOGGLE_SUB_CATEGORY_CHECKBOX(state, payload) {
		const newCategoryIndex = state.productCategory.data.findIndex(el => el.parent_category_id === payload.category_id)
		const newCategoryArray = [...state.productCategory.data]
		const newParentCategory = newCategoryArray[newCategoryIndex]
		const newSubCategoryIndex = newParentCategory.findIndex(el => el.id === payload.id)
		newParentCategory[newSubCategoryIndex] = {...newParentCategory[newSubCategoryIndex], checked: payload.value}
		newCategoryArray[newCategoryIndex] = newParentCategory
		state.productCategory.data = newCategoryArray
	},
	FILTER_CUSTOMER_ALL(state, _) {
		console.log(state.copyShopCustomers.data)
		state.shopCustomers.data = state.copyShopCustomers.data
	},
	FILTER_SEARCH_CUSTOMER(state, payload) {
		const filterCustomerName = state.copyShopCustomers.data.filter(item =>
			item.customer_name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
		)
		const filterCustomerNumber = state.copyShopCustomers.data.filter(item =>
			item.customer_number === parseInt(payload.value)
		)
		const filterOrganNumber = state.copyShopCustomers.data.filter(item =>
			item.organization_number === parseInt(payload.value)
		)
		if (filterCustomerName.length > 0) {
			state.shopCustomers.data = filterCustomerName
		} else if (filterCustomerNumber.length > 0) {
			state.shopCustomers.data = filterCustomerNumber
		} else if (filterOrganNumber.length > 0) {
			state.shopCustomers.data = filterOrganNumber
		} else {
			state.shopCustomers.data = []
		}
	},
	FILTER_ORDERS_ALL(state, _) {
		state.orders.data = state.copyOrders.data
	},
	FILTER_SEARCH_ORDER(state, payload) {
		const filterOrderCusName = state.copyOrders.data.filter(item =>
			item.customer_name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
		)
		const filterOrderNumber = state.copyOrders.data.filter(item =>
			item.your_order_number.includes(payload.value)
		)
		const filterOrderRef = state.copyOrders.data.filter(item =>
			item.your_reference.includes(payload.value)
		)
		if (filterOrderCusName.length > 0) {
			state.orders.data = filterOrderCusName
		} else if (filterOrderNumber.length > 0) {
			state.orders.data = filterOrderNumber
		} else if (filterOrderRef.length > 0) {
			state.orders.data = filterOrderRef
		} else {
			state.orders.data = []
		}
	},
	FILTER_INVOICE_ALL(state, _) {
		state.invoice.data = state.copyInvoice.data
	},
	FILTER_SEARCH_INVOICE(state, payload) {
		const filterInvoiceCusName = state.copyInvoice.data.filter(item =>
			item.customer_name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
		)
		const filterInvoiceNumber = state.copyInvoice.data.filter(item =>
			Object.values(item).includes(parseInt(payload.value))
		)
		const filterInvoiceRef = state.copyInvoice.data.filter(item =>
			item.your_reference.includes(payload.value)
		)
		if (filterInvoiceCusName.length > 0) {
			state.invoice.data = filterInvoiceCusName
		} else if (filterInvoiceNumber.length > 0) {
			state.invoice.data = filterInvoiceNumber
		} else if (filterInvoiceRef.length > 0) {
			state.invoice.data = filterInvoiceRef
		} else {
			state.invoice.data = []
		}
	},
	SORT_ARTICLE_QTY_ASC(state, _) {
		const sortAsc = state.copyArticles.data.sort((a, b) => {
			return a.quantity_in_stock - b.quantity_in_stock
		})
		state.articles.data = sortAsc
	},
	SORT_ARTICLE_QTY_DESC(state, _) {
		const sortDesc = state.copyArticles.data.sort((a, b) => {
			return b.quantity_in_stock - a.quantity_in_stock
		})
		state.articles.data = sortDesc
	},
	SORT_ARTICLE_PRICE_ASC(state, _) {
		const sortAsc = state.copyArticles.data.sort((a, b) => {
			return a.price - b.price
		})
		state.articles.data = sortAsc
	},
	SORT_ARTICLE_PRICE_DESC(state, _) {
		const sortDesc = state.copyArticles.data.sort((a, b) => {
			return b.price - a.price
		})
		state.articles.data = sortDesc
	},
	SORT_INVOICE_CUSTOMER_NAME_ASC(state, _) {
		const sortAsc = state.copyInvoice.data.sort((a, b) => {
			return a.customer_name > b.customer_name
		})
		state.invoice.data = sortAsc
	},
	SORT_INVOICE_CUSTOMER_NAME_DESC(state, _) {
		const sortDesc = state.copyInvoice.data.sort((a, b) => {
			return b.customer_name > a.customer_name
		})
		state.invoice.data = sortDesc
	},
	SORT_INVOICE_STATUS_ASC(state, _) {
		const sortAsc = state.copyInvoice.data.sort((a, b) => {
			return a.order_status > b.order_status
		})
		state.invoice.data = sortAsc
	},
	SORT_INVOICE_STATUS_DESC(state, _) {
		const sortDesc = state.copyInvoice.data.sort((a, b) => {
			return b.order_status > a.order_status
		})
		state.invoice.data = sortDesc
	},
	SORT_INVOICE_NUMBER_ASC(state, _) {
		const sortAsc = state.copyInvoice.data.sort((a, b) => {
			return a.document_number - b.document_number
		})
		state.invoice.data = sortAsc
	},
	SORT_INVOICE_NUMBER_DESC(state, _) {
		const sortDesc = state.copyInvoice.data.sort((a, b) => {
			return b.document_number - a.document_number
		})
		state.invoice.data = sortDesc
	},
	SORT_INVOICE_DATE_ASC(state, _) {
		const sortDesc = state.copyInvoice.data.sort((a, b) => {
			return new Date(a.order_date) - new Date(b.order_date)
		})
		state.invoice.data = sortDesc
	},
	SORT_INVOICE_DATE_DESC(state, _) {
		const sortDesc = state.copyInvoice.data.sort((a, b) => {
			return new Date(b.order_date) - new Date(a.order_date)
		})
		state.invoice.data = sortDesc
	},
	SORT_INVOICE_SUMMARY_ASC(state, _) {
		const sortDesc = state.copyInvoice.data.sort((a, b) => {
			return a.total_to_pay - b.total_to_pay
		})
		state.invoice.data = sortDesc
	},
	SORT_INVOICE_SUMMARY_DESC(state, _) {
		const sortDesc = state.copyInvoice.data.sort((a, b) => {
			return b.total_to_pay - a.total_to_pay
		})
		state.invoice.data = sortDesc
	},

	SORT_CUSTOMER_NAME_ASC(state, _) {
		const sortAsc = state.copyOrders.data.sort((a, b) => {
			return a.customer_name > b.customer_name
		})
		state.orders.data = sortAsc
	},
	SORT_CUSTOMER_NAME_DESC(state, _) {
		const sortDesc = state.copyOrders.data.sort((a, b) => {
			return b.customer_name > a.customer_name
		})
		state.orders.data = sortDesc
	},
	SORT_B2B_ORDER_NUMBER_ASC(state, _) {
		const sortAsc = state.copyOrders.data.sort((a, b) => {
			return a.in_house_order_no - b.in_house_order_no
		})
		state.orders.data = sortAsc
	},
	SORT_B2B_ORDER_NUMBER_DESC(state, _) {
		const sortDesc = state.copyOrders.data.sort((a, b) => {
			return b.in_house_order_no - a.in_house_order_no
		})
		state.orders.data = sortDesc
	},
	SORT_ORDER_NUMBER_ASC(state, _) {
		const sortAsc = state.copyOrders.data.sort((a, b) => {
			return a.document_number - b.document_number
		})
		state.orders.data = sortAsc
	},
	SORT_ORDER_NUMBER_DESC(state, _) {
		const sortDesc = state.copyOrders.data.sort((a, b) => {
			return b.document_number - a.document_number
		})
		state.orders.data = sortDesc
	},
	SORT_ORDER_DATE_ASC(state, _) {
		const sortDesc = state.copyOrders.data.sort((a, b) => {
			return new Date(a.order_date) - new Date(b.order_date)
		})
		state.orders.data = sortDesc
	},
	SORT_ORDER_DATE_DESC(state, _) {
		const sortDesc = state.copyOrders.data.sort((a, b) => {
			return new Date(b.order_date) - new Date(a.order_date)
		})
		state.orders.data = sortDesc
	},
	SORT_ORDER_STATUS_ASC(state, _) {
		const sortAsc = state.copyOrders.data.sort((a, b) => {
			return a.order_status > b.order_status
		})
		state.orders.data = sortAsc
	},
	SORT_ORDER_STATUS_DESC(state, _) {
		const sortDesc = state.copyOrders.data.sort((a, b) => {
			return b.order_status > a.order_status
		})
		state.orders.data = sortDesc
	},
	SORT_ORDER_SUMMARY_ASC(state, _) {
		const sortDesc = state.copyOrders.data.sort((a, b) => {
			return a.total_to_pay - b.total_to_pay
		})
		state.orders.data = sortDesc
	},
	SORT_ORDER_SUMMARY_DESC(state, _) {
		const sortDesc = state.copyOrders.data.sort((a, b) => {
			return b.total_to_pay - a.total_to_pay
		})
		state.orders.data = sortDesc
	},
	SORT_ARTICLE_NUMBER_ASC(state, _) {
		const sortAsc = state.articles.data.sort((a, b) => {
			return a.article_number - b.article_number
		})
		state.articles.data = sortAsc
	},
	SORT_ARTICLE_NUMBER_DESC(state, _) {
		const sortDesc = state.articles.data.sort((a, b) => {
			return b.article_number - a.article_number
		})
		state.articles.data = sortDesc
	},
	SORT_ARTICLE_NAME_ASC(state, _) {
		const sortAsc = state.articles.data.sort((a, b) => {
			return a.product_name === b.product_name ? 0 : a.product_name > b.product_name ? 1 : -1
		})
		state.articles.data = sortAsc
	},
	SORT_ARTICLE_NAME_DSC(state, _) {
		const sortDesc = state.articles.data.sort((a, b) => {
			return a.product_name === b.product_name ? 0 : a.product_name < b.product_name ? 1 : -1
		})
		state.articles.data = sortDesc
	},
	SORT_ARTICLE_DESC_ASC(state, _) {
		const sortAsc = state.articles.data.sort((a, b) => {
			return a.description === b.description ? 0 : a.description > b.description ? 1 : -1
		})
		state.articles.data = sortAsc
	},
	SORT_ARTICLE_DESC_DSC(state, _) {
		const sortDesc = state.articles.data.sort((a, b) => {
			return a.description === b.description ? 0 : a.description < b.description ? 1 : -1
		})
		state.articles.data = sortDesc
	},
	SORT_ARTICLE_IMAGE_ASC(state, _) {
		const sortAsc = state.articles.data.sort((a, b) => {
			return a.image.length - b.image.length
		})
		state.articles.data = sortAsc
	},
	SORT_ARTICLE_IMAGE_DSC(state, _) {
		const sortDesc = state.articles.data.sort((a, b) => {
			return b.image.length - a.image.length
		})
		state.articles.data = sortDesc
	},
	SORT_ARTICLE_VIDEO_ASC(state, _) {
		const sortAsc = state.articles.data.sort((a, b) => {
			if (a.product_video_url === b.product_video_url) {
				return 0
			} else if (a.product_video_url !== null && b.product_video_url === null) {
				return 1
			} else if (a.product_video_url === null && b.product_video_url !== null) {
				return -1
			} else {
				return 0
			}
		})
		state.articles.data = sortAsc
	},
	SORT_ARTICLE_VIDEO_DSC(state, _) {
		const sortDesc = state.articles.data.sort((a, b) => {
			if (b.product_video_url === a.product_video_url) {
				return 0
			} else if (b.product_video_url !== null && a.product_video_url === null) {
				return 1
			} else if (b.product_video_url === null && a.product_video_url !== null) {
				return -1
			} else {
				return 0
			}
		})
		state.articles.data = sortDesc
	},
	SORT_ARTICLE_QUANTITY_ASC(state, _) {
		const sortAsc = state.articles.data.sort((a, b) => {
			return a.quantity_in_stock - b.quantity_in_stock
		})
		state.articles.data = sortAsc
	},
	SORT_ARTICLE_QUANTITY_DESC(state, _) {
		const sortDesc = state.articles.data.sort((a, b) => {
			return b.quantity_in_stock - a.quantity_in_stock
		})
		state.articles.data = sortDesc
	},
	SORT_ARTICLE_SALESPRICE_ASC(state, _) {
		const sortAsc = state.articles.data.sort((a, b) => {
			return a.price - b.price
		})
		state.articles.data = sortAsc
	},
	SORT_ARTICLE_SALESPRICE_DESC(state, _) {
		const sortDesc = state.articles.data.sort((a, b) => {
			return b.price - a.price
		})
		state.articles.data = sortDesc
	},
	SORT_ARTICLE_COST_PRICE_ASC(state, _) {
		const sortAsc = state.articles.data.sort((a, b) => {
			return a.purchase_price - b.purchase_price
		})
		state.articles.data = sortAsc
	},
	SORT_ARTICLE_COST_PRICE_DESC(state, _) {
		const sortDesc = state.articles.data.sort((a, b) => {
			return b.purchase_price - a.purchase_price
		})
		state.articles.data = sortDesc
	},
	SORT_ARTICLE_VAT_RATE_ASC(state, _) {
		const sortAsc = state.articles.data.sort((a, b) => {
			return a.vat_rate - b.vat_rate
		})
		state.articles.data = sortAsc
	},
	SORT_ARTICLE_VAT_RATE_DESC(state, _) {
		const sortDesc = state.articles.data.sort((a, b) => {
			return b.vat_rate - a.vat_rate
		})
		state.articles.data = sortDesc
	},
	SORT_ARTICLE_MARGIN_ASC(state, _) {
		const sortAsc = state.articles.data.sort((a, b) => {
			return a.margin - b.margin
		})
		state.articles.data = sortAsc
	},
	SORT_ARTICLE_MARGIN_DESC(state, _) {
		const sortDesc = state.articles.data.sort((a, b) => {
			return b.margin - a.margin
		})
		state.articles.data = sortDesc
	},
	SORT_CUSTOMERS_NUMBER_ASC(state, _) {
		const sortAsc = state.copyShopCustomers.data.sort((a, b) => {
			return parseInt(a.customer_number) - parseInt(b.customer_number)
		})
		state.shopCustomers.data = sortAsc
	},
	SORT_CUSTOMERS_NUMBER_DESC(state, _) {
		const sortDesc = state.copyShopCustomers.data.sort((a, b) => {
			return parseInt(b.customer_number) - parseInt(a.customer_number)
		})
		state.shopCustomers.data = sortDesc
	},
	SORT_CUSTOMERS_NAME_ASC(state, _) {
		const sortAsc = state.copyShopCustomers.data.sort((a, b) => {
			return a.customer_name === b.customer_name ? 0 : a.customer_name > b.customer_name ? 1 : -1
		})
		state.shopCustomers.data = sortAsc
	},
	SORT_CUSTOMERS_NAME_DESC(state, _) {
		const sortDesc = state.copyShopCustomers.data.sort((a, b) => {
			return a.customer_name === b.customer_name ? 0 : a.customer_name < b.customer_name ? 1 : -1
		})
		state.shopCustomers.data = sortDesc
	},
	SORT_CUSTOMERS_CATE_ASC(state, _) {
		const sortAsc = state.copyShopCustomers.data.sort((a, b) => {
			if (a.category && b.category) {
				return a.category.name === b.category.name ? 0 : a.category.name > b.category.name ? 1 : -1
			}
		})
		state.shopCustomers.data = sortAsc
	},
	SORT_CUSTOMERS_CATE_DESC(state, _) {
		const sortDesc = state.copyShopCustomers.data.sort((a, b) => {
			if (a.category && b.category) {
				return a.category.name === b.category.name ? 0 : a.category.name < b.category.name ? 1 : -1
			}
		})
		state.shopCustomers.data = sortDesc
	},
	SORT_CUSTOMERS_COST_CENTER_ASC(state, _) {
		const sortAsc = state.copyShopCustomers.data.sort((a, b) => {
			if (a.category && a.category.cost_center && b.category && b.category.cost_center) {
				return a.category.cost_center.description === b.category.cost_center.description ? 0 : a.category.cost_center.description > b.category.cost_center.description ? 1 : -1
			}
		})
		state.shopCustomers.data = sortAsc
	},
	SORT_CUSTOMERS_COST_CENTER_DESC(state, _) {
		const sortDesc = state.copyShopCustomers.data.sort((a, b) => {
			if (a.category && a.category.cost_center && b.category && b.category.cost_center) {
				return a.category.cost_center.description === b.category.cost_center.description ? 0 : a.category.cost_center.description < b.category.cost_center.description ? 1 : -1
			}
		})
		state.shopCustomers.data = sortDesc
	},
	SORT_CUSTOMERS_ACCOUNT_NUM_ASC(state, _) {
		const sortAsc = state.copyShopCustomers.data.sort((a, b) => {
			if (a.category && b.category) {
				return a.category.account_number - b.category.account_number
			}
		})
		state.shopCustomers.data = sortAsc
	},
	SORT_CUSTOMERS_ACCOUNT_NUM_DESC(state, _) {
		const sortDesc = state.copyShopCustomers.data.sort((a, b) => {
			if (a.category && b.category) {
				return b.category.account_number - a.category.account_number
			}
		})
		state.shopCustomers.data = sortDesc
	},
	SORT_CUSTOMERS_ORDER_AMOUNT_ASC(state, _) {
		const sortAsc = state.copyShopCustomers.data.sort((a, b) => {
			return parseFloat(b.total_order_amount) > parseFloat(a.total_order_amount)
		})
		state.shopCustomers.data = sortAsc
	},
	SORT_CUSTOMERS_ORDER_AMOUNT_DESC(state, _) {
		const sortDesc = state.copyShopCustomers.data.sort((a, b) => {
			return parseFloat(b.total_order_amount) > parseFloat(a.total_order_amount)
		})
		state.shopCustomers.data = sortDesc
	},
	SORT_CUSTOMERS_EMAIL_ASC(state, _) {
		const sortAsc = state.copyShopCustomers.data.sort((a, b) => {
			return a.email === b.email ? 0 : a.email > b.email ? 1 : -1
		})
		state.shopCustomers.data = sortAsc
	},
	SORT_CUSTOMERS_EMAIL_DESC(state, _) {
		const sortDesc = state.copyShopCustomers.data.sort((a, b) => {
			return a.email === b.email ? 0 : a.email < b.email ? 1 : -1
		})
		state.shopCustomers.data = sortDesc
	},
	SORT_CUSTOMERS_DRAWER_NAME_ASC(state, _) {
		const sortAsc = state.copyShopCustomersDrawer.data.sort((a, b) => {
			return a.customer_name > b.customer_name
		})
		state.shopCustomersDrawer.data = sortAsc
	},
	SORT_CUSTOMERS_DRAWER_NAME_DESC(state, _) {
		const sortDesc = state.copyShopCustomersDrawer.data.sort((a, b) => {
			return b.customer_name > a.customer_name
		})
		state.shopCustomersDrawer.data = sortDesc
	},
	SORT_CUSTOMERS_DRAWER_NUMBER_ASC(state, _) {
		const sortAsc = state.copyShopCustomersDrawer.data.sort((a, b) => {
			return a.customer_name > b.customer_name
		})
		state.shopCustomersDrawer.data = sortAsc
	},
	SORT_CUSTOMERS_DRAWER_NUMBER_DESC(state, _) {
		const sortDesc = state.copyShopCustomersDrawer.data.sort((a, b) => {
			return b.customer_name > a.customer_name
		})
		state.shopCustomersDrawer.data = sortDesc
	},
	SORT_CUSTOMERS_DRAWER_CATE_ASC(state, _) {
		const sortAsc = state.copyShopCustomersDrawer.data.sort((a, b) => {
			if (a.category && b.category) {
				return a.category.name > b.category.name
			}
		})
		state.shopCustomersDrawer.data = sortAsc
	},
	SORT_CUSTOMERS_DRAWER_CATE_DESC(state, _) {
		const sortDesc = state.copyShopCustomersDrawer.data.sort((a, b) => {
			if (a.category && b.category) {
				return b.category.name > a.category.name
			}
		})
		state.shopCustomersDrawer.data = sortDesc
	},
	SORT_CUSTOMERS_DRAWER_ACCOUNT_NUM_ASC(state, _) {
		const sortAsc = state.copyShopCustomersDrawer.data.sort((a, b) => {
			if (a.category && b.category) {
				return a.category.account_number - b.category.account_number
			}
		})
		state.shopCustomersDrawer.data = sortAsc
	},
	SORT_CUSTOMERS_DRAWER_ACCOUNT_NUM_DESC(state, _) {
		const sortDesc = state.copyShopCustomersDrawer.data.sort((a, b) => {
			if (a.category && b.category) {
				return b.category.account_number - a.category.account_number
			}
		})
		state.shopCustomersDrawer.data = sortDesc
	},
	SORT_CUSTOMERS_DRAWER_COST_CENTER_ASC(state, _) {
		const sortAsc = state.copyShopCustomersDrawer.data.sort((a, b) => {
			if (a.category && a.category.cost_center && b.category && b.category.cost_center) {
				return a.category.cost_center.description > b.category.cost_center.description
			}
		})
		state.shopCustomersDrawer.data = sortAsc
	},
	SORT_CUSTOMERS_DRAWER_COST_CENTER_DESC(state, _) {
		const sortDesc = state.copyShopCustomersDrawer.data.sort((a, b) => {
			if (a.category && a.category.cost_center && b.category && b.category.cost_center) {
				return b.category.cost_center.description > a.category.cost_center.description
			}
		})
		state.shopCustomersDrawer.data = sortDesc
	},

	SORT_CUSTOMERS_SETTING_CUSTOMER_NAME_ASC(state, _) {
		const sortAsc = state.copyShopCustomersDrawerSetting.data.sort((a, b) => {
			return a.customer_name > b.customer_name
		})
		state.shopCustomersDrawerSetting.data = sortAsc
	},
	SORT_CUSTOMERS_SETTING_CUSTOMER_NAME_DESC(state, _) {
		const sortDesc = state.copyShopCustomersDrawerSetting.data.sort((a, b) => {
			return b.email > a.email
		})
		state.shopCustomersDrawerSetting.data = sortDesc
	},
	SORT_CUSTOMERS_SETTING_EMAIL_ASC(state, _) {
		const sortAsc = state.copyShopCustomersDrawerSetting.data.sort((a, b) => {
			return a.email > b.email
		})
		state.shopCustomersDrawerSetting.data = sortAsc
	},
	SORT_CUSTOMERS_SETTING_EMAIL_DESC(state, _) {
		const sortDesc = state.copyShopCustomersDrawerSetting.data.sort((a, b) => {
			return b.email > a.email
		})
		state.shopCustomersDrawerSetting.data = sortDesc
	},
	SORT_CUSTOMERS_SETTING_COST_CENTER_ASC(state, _) {
		const sortAsc = state.copyShopCustomersDrawerSetting.data.sort((a, b) => {
			if (a.category && a.category.cost_center && b.category && b.category.cost_center) {
				return a.category.cost_center.description > b.category.cost_center.description
			}
		})
		state.shopCustomersDrawerSetting.data = sortAsc
	},
	SORT_CUSTOMERS_SETTING_COST_CENTER_DESC(state, _) {
		const sortDesc = state.copyShopCustomersDrawerSetting.data.sort((a, b) => {
			if (a.category && a.category.cost_center && b.category && b.category.cost_center) {
				return b.category.cost_center.description > a.category.cost_center.description
			}
		})
		state.shopCustomersDrawerSetting.data = sortDesc
	},
	SORT_CUSTOMERS_SETTING_MAX_LIMIT_ASC(state, _) {
		const sortAsc = state.copyShopCustomersDrawerSetting.data.sort((a, b) => {
			return a.max_credit_limit - b.max_credit_limit
		})
		state.shopCustomersDrawerSetting.data = sortAsc
	},
	SORT_CUSTOMERS_SETTING_MAX_LIMIT_DESC(state, _) {
		const sortDesc = state.copyShopCustomersDrawerSetting.data.sort((a, b) => {
			return b.max_credit_limit - a.max_credit_limit
		})
		state.shopCustomersDrawerSetting.data = sortDesc
	},
	SORT_SHOP_USERS_FIRSTNAME_ASC(state, _) {
		const sortAsc = state.copyShopUsers.sort((a, b) => {
			if (a.first_name && b.first_name) {
				return a.first_name > b.first_name
			}
		})
		state.shopUsers = sortAsc
	},
	SORT_SHOP_USERS_FIRSTNAME_DSC(state, _) {
		const sortAsc = state.copyShopUsers.sort((a, b) => {
			if (b.first_name && a.first_name) {
				return b.first_name > a.first_name
			}
		})
		state.shopUsers = sortAsc
	},
	SORT_SHOP_USERS_LASTNAME_ASC(state, _) {
		const sortAsc = state.copyShopUsers.sort((a, b) => {
			if (a.last_name && b.last_name) {
				return a.last_name > b.last_name
			}
		})
		state.shopUsers = sortAsc
	},
	SORT_SHOP_USERS_LASTNAME_DSC(state, _) {
		const sortAsc = state.copyShopUsers.sort((a, b) => {
			if (b.last_name && a.last_name) {
				return b.last_name > a.last_name
			}
		})
		state.shopUsers = sortAsc
	},
	SORT_SHOP_USERS_ROLL_ASC(state, _) {
		const sortAsc = state.copyShopUsers.sort((a, b) => {
			if (a.user && b.user) {
				return a.user.groups[0].name > b.user.groups[0].name
			}
		})
		state.shopUsers = sortAsc
	},
	SORT_SHOP_USERS_ROLL_DSC(state, _) {
		const sortAsc = state.copyShopUsers.sort((a, b) => {
			if (b.user && a.user) {
				return b.user.groups[0].name > a.user.groups[0].name
			}
		})
		state.shopUsers = sortAsc
	},
	SORT_BILLING_NAME_ASC(state, _) {
		const sortAsc = state.copyShopBilling.sort((a, b) => {
			return a.customer_name > b.customer_name
		})
		state.shopBilling = sortAsc
	},
	SORT_BILLING_NAME_DSC(state, _) {
		const sortDesc = state.copyShopBilling.sort((a, b) => {
			return b.customer_name > a.customer_name
		})
		state.shopBilling = sortDesc
	},
	SORT_BILLING_DATE_ASC(state, _) {
		const sortAsc = state.copyShopBilling.sort((a, b) => {
			return new Date(a.invoice_date) - new Date(b.invoice_date)
		})
		state.shopBilling = sortAsc
	},
	SORT_BILLING_DATE_DESC(state, _) {
		const sortDesc = state.copyShopBilling.sort((a, b) => {
			return new Date(b.invoice_date) - new Date(a.invoice_date)
		})
		state.shopBilling = sortDesc
	},
	SORT_BILLING_DUE_DATE_ASC(state, _) {
		const sortAsc = state.copyShopBilling.sort((a, b) => {
			return new Date(a.due_date) - new Date(b.due_date)
		})
		state.shopBilling = sortAsc
	},
	SORT_BILLING_DUE_DATE_DESC(state, _) {
		const sortDesc = state.copyShopBilling.sort((a, b) => {
			return new Date(b.due_date) - new Date(a.due_date)
		})
		state.shopBilling = sortDesc
	},
	SORT_BILLING_PRICE_ASC(state, _) {
		const sortAsc = state.copyShopBilling.sort((a, b) => {
			return a.total - b.total
		})
		state.shopBilling = sortAsc
	},
	SORT_BILLING_PRICE_DESC(state, _) {
		const sortDesc = state.copyShopBilling.sort((a, b) => {
			return b.total - a.total
		})
		state.shopBilling = sortDesc
	},
	SORT_LOG_INITIATOR_ASC(state, _) {
		const sortAsc = state.copyApplicationLog.data.sort((a, b) => {
			return a['initiator__username'] === b['initiator__username'] ? 0 : a['initiator__username'] > b['initiator__username'] ? 1 : -1
		})
		state.applicationLog.data = sortAsc
	},
	SORT_LOG_INITIATOR_DESC(state, _) {
		// const sortDesc = state.copyApplicationLog.data.sort((a, b) => {
		// 	return b.initiator__username < a.initiator__username
		// })
		const sortDesc = state.copyApplicationLog.data.sort((a, b) => {
			return a['company__name'] === b['initiator__username'] ? 0 : a['initiator__username'] < b['initiator__username'] ? 1 : -1
		})
		state.applicationLog.data = sortDesc
	},
	SORT_LOG_CUSTOMER_NAME_ASC(state, _) {
		const sortAsc = state.copyApplicationLog.data.sort((a, b) => {
			return a['company__name'] === b['company__name'] ? 0 : a['company__name'] > b['company__name'] ? 1 : -1
		})
		state.applicationLog.data = sortAsc
	},
	SORT_LOG_CUSTOMER_NAME_DESC(state, _) {
		const sortDesc = state.copyApplicationLog.data.sort((a, b) => {
			return a['company__name'] === b['company__name'] ? 0 : a['company__name'] < b['company__name'] ? 1 : -1
		})
		state.applicationLog.data = sortDesc
	},
	SORT_LOG_DESCRIPTION_ASC(state, _) {
		const sortAsc = state.copyApplicationLog.data.sort((a, b) => {
			return a['description'] === b['description'] ? 0 : a['description'] > b['description'] ? 1 : -1
		})
		state.applicationLog.data = sortAsc
	},
	SORT_LOG_DESCRIPTION_DESC(state, _) {
		const sortDesc = state.copyApplicationLog.data.sort((a, b) => {
			return a['description'] === b['description'] ? 0 : a['description'] < b['description'] ? 1 : -1
		})
		state.applicationLog.data = sortDesc
	},
	SORT_LOG_DATE_ASC(state, _) {
		const sortAsc = state.copyApplicationLog.data.sort((a, b) => {
			return new Date(a.date) - new Date(b.date)
		})
		state.applicationLog.data = sortAsc
	},
	SORT_LOG_DATE_DESC(state, _) {
		const sortDesc = state.copyApplicationLog.data.sort((a, b) => {
			return new Date(b.date) - new Date(a.date)
		})
		state.applicationLog.data = sortDesc
	},
	SORT_LOG_TYPE_ASC(state, _) {
		const sortAsc = state.copyApplicationLog.data.sort((a, b) => {
			return a['event_state'] === b['event_state'] ? 0 : a['event_state'] > b['event_state'] ? 1 : -1
		})
		state.applicationLog.data = sortAsc
	},
	SORT_LOG_TYPE_DESC(state, _) {
		const sortDesc = state.copyApplicationLog.data.sort((a, b) => {
			return a['event_state'] === b['event_state'] ? 0 : a['event_state'] < b['event_state'] ? 1 : -1
		})
		state.applicationLog.data = sortDesc
	},
	UPDATE_SHOPOWNER_TOUR(state, payload) {
		state.shopOwnerTour[payload.view] = true
		storeTourGuide(state.shopOwnerTour)
	},
	UPDATE_SHOPOWNER_TOUR_ALL(state, payload) {
		state.shopOwnerTour = payload
		storeTourGuide(state.shopOwnerTour)
	},
	GET_SHOPOWNER_TOUR_ALL(state, payload) {
		state.shopOwnerTour = getShopOwnerTourGuide()
	},
	UPDATE_BLOG_CATEGORY(state, payload) {
		payload.data.forEach(element => {
			element.checked = false
		})
		state.blogCategory = payload.data
	},
	UPDATE_CHECKED_BLOG_CATEGORY(state, payload) {
		const blogCategory = state.blogCategory
		payload.map(item => {
			const catIndex = blogCategory.findIndex(itemIndex => itemIndex.id === item.id)
			const singleCat = blogCategory[catIndex]
			singleCat.checked = true
			blogCategory[catIndex] = singleCat
		})
		state.blogCategory = blogCategory
	},
	RESET_BLOG_CATEGORY(state, payload) {
		state.blogCategory.forEach(element => {
			element.checked = payload
		})
	},
	UPDATE_BLOG_TAG(state, payload) {
		state.blogTag = payload
	},
	UPDATE_BLOG_POST(state, payload) {
		payload.data.map(item => {
			item.checked = false
		})
		state.blogPost = payload
		state.blogPostCopy = payload
	},
	UPDATE_SINGLE_BLOG_POST(state, payload) {
		state.singleBlogPost = payload
	},
	UPDATE_BLOG_POST_DATA(state, payload) {
		payload.data.map(item => {
			item.checked = false
		})
		state.blogPost.data = payload.data
	},
	TOGGLE_BLOG_CATEGORY(state, payload) {
		const categoryIndex = state.blogCategory.findIndex(item => parseInt(item.id) === parseInt(payload.category.id))
		const stateCategoryCopy = [...state.blogCategory]
		stateCategoryCopy[categoryIndex] = {...stateCategoryCopy[categoryIndex], checked: payload.value}
		state.blogCategory = stateCategoryCopy
	},
	SORT_PRODUCT_CATEGORY_ASC(state, _) {
		const sortAsc = state.productCategory.data.sort((a, b) => {
			return a.parent_category_name === b.parent_category_name ? 0 : a.parent_category_name > b.parent_category_name ? 1 : -1
		})
		state.productCategory.data = sortAsc
	},
	SORT_PRODUCT_CATEGORY_DSC(state, _) {
		const sortDsc = state.productCategory.data.sort((a, b) => {
			return a.parent_category_name === b.parent_category_name ? 0 : a.parent_category_name < b.parent_category_name ? 1 : -1
		})
		state.productCategory.data = sortDsc
	},
	SORT_SHOP_USER_NAME_ASC(state, _) {
		const sortAsc = state.shopCustomers.data.sort((a, b) => {
			return a.customer_name === b.customer_name ? 0 : a.customer_name > b.customer_name ? 1 : -1
		})
		state.shopCustomers.data = sortAsc
	},
	SORT_SHOP_USER_NAME_DSC(state, _) {
		const sortDsc = state.shopCustomers.data.sort((a, b) => {
			return a.customer_name === b.customer_name ? 0 : a.customer_name < b.customer_name ? 1 : -1
		})
		state.shopUsers.data = sortDsc
	},
	TOGGLE_ALL_BLOG_CHECKBOX(state, payload) {
		state.blogPost.data.forEach(element => {
			element.checked = payload
		})
	},
	TOGGLE_BLOG_CHECKBOX(state, payload) {
		const newPostIndex = state.blogPost.data.findIndex(el => el.id === payload.id)
		const newPostArray = [...state.blogPost.data]
		newPostArray[newPostIndex] = {...newPostArray[newPostIndex], checked: !payload.value}
		state.blogPost.data = newPostArray
	},

	FILTER_BLOG_SEARCH(state, payload) {
		const payloadCopy = state.blogPostCopy
		const filterTitle = payloadCopy.data.filter(item =>
			item.title.toLowerCase().includes(`${payload.value.toLowerCase()}`)
		)
		const filterCategory = payloadCopy.data.filter(item =>
			categoryFiltering({item: item.category, value: payload.value})
		)
		if (filterTitle.length > 0) {
			state.blogPost.data = filterTitle
		}
		if (filterCategory.length > 0) {
			state.blogPost.data = filterCategory
		}
	},
	RESET_BLOG_POST(state, _) {
		state.blogPost.data = state.blogPostCopy.data
	},
	SET_BLOG_POST_PREVIEW(state, payload) {
		state.blogPreview = payload
	},
	SET_blogImage(state, payload) {
		state.blogImage = payload.image
	},
	UPDATE_FAQZ(state, payload) {
		state.faqPost = {}
		state.faqPost = payload
	},
	UPDATE_FAQ_CATEGORY(state, payload) {
		state.faqPostCategory = payload
	},
	FILTER_BLOG(state, payload) {
		const filterCategory = state.blogPostCopy.data.filter(item =>
			categoryFiltering({item: item.category, value: payload.value})
		)
		if (filterCategory.length > 0) {
			state.blogPost.data = filterCategory
		}
	},
	SORT_CUSTOMER_CATEGORY_NAME_ASC(state, _) {
		const sortAsc = state.customerCategory.results.sort((a, b) => {
			return a.description === b.name ? 0 : a.name > b.name ? 1 : -1
		})
		state.customerCategory = sortAsc
	},
	SORT_CUSTOMER_CATEGORY_NAME_DSC(state, _) {
		const sortDesc = state.customerCategory.results.sort((a, b) => {
			return a.name === b.name ? 0 : a.name < b.name ? 1 : -1
		})
		state.customerCategory = sortDesc
	},
	UPDATE_COMPANY_CHECKOUT(state, payload) {
		state.companyCheckoutCustomizer = payload
	},
	UPDATE_COMPANY_SUBSCRIPTION_SETTING(state, payload) {
		state.companySubscriptionSettings = payload
		saveCompanyCustomizationSetting(payload)

	},
	SET_CATEGORY_STRUCTURE(state, payload) {
		let results = payload.data.results.filter(item => item.child_node.length > 0)
		state.articleCategoryStructure = payload.data

	},
	SET_ARTICLE_ACTIVE_FILTER(state, payload) {
		let currentFilter = state.articleFilter
		let filterExist = currentFilter.findIndex(item => item.mode === payload.mode)
		if (filterExist > -1) {
			currentFilter[filterExist] = payload
		} else {
			currentFilter.push(payload)
		}
		state.articleFilter = currentFilter
	},
	SET_ARTICLE_PRODUCT_PRICE_FILTER(state, payload) {
		let currentFilter = state.productPriceFilter
		let filterExist = currentFilter.findIndex(item => item.mode === payload.mode)
		if (filterExist > -1) {
			currentFilter[filterExist] = payload
		} else {
			currentFilter.push(payload)
		}
		state.productPriceFilter = currentFilter
	},
	REMOVE_ARTICLE_ACTIVE_FILTER(state, payload) {
		let currentFilter = state.articleFilter
		state.articleFilter = currentFilter.filter(item => item.mode !== payload.mode)
	},
	REMOVE_ARTICLE_PRODUCT_PRICE_FILTER(state, payload) {
		let currentFilter = state.productPriceFilter
		state.productPriceFilter = currentFilter.filter(item => item.mode !== payload.mode)
	},
	SET_ORDER_ACTIVE_FILTER(state, payload) {
		let currentFilter = state.orderFilter
		let filterExist = currentFilter.findIndex(item => item.mode === payload.mode)
		if (filterExist > -1) {
			currentFilter[filterExist] = payload
		} else {
			currentFilter.push(payload)
		}
		state.orderFilter = currentFilter
	},
	REMOVE_ORDER_FILTER(state, payload) {
		let currentFilter = state.orderFilter
		state.orderFilter = currentFilter.filter(item => item.mode !== payload.mode)
	},
	SET_CUSTOMER_ACTIVE_FILTER(state, payload) {
		let currentFilter = state.customerFilter
		let filterExist = currentFilter.findIndex(item => item.mode === payload.mode)
		if (filterExist > -1) {
			currentFilter[filterExist] = payload
		} else {
			currentFilter.push(payload)
		}
		state.customerFilter = currentFilter
	},
	REMOVE_CUSTOMER_FILTER(state, payload) {
		let currentFilter = state.customerFilter
		state.customerFilter = currentFilter.filter(item => item.mode !== payload.mode)
	},
	SET_UNIT_TYPES(state, payload) {
		state.unitTypeObj = payload
	},
	SET_ALL_ARTICLES(state, payload) {
		state.allArticles = [...state.allArticles, ...payload.data]
	},
	CLEAR_ALL_ARTICLES(state, _) {
		state.allArticles = []
	},
	SET_ALL_ACTIVE_ACCOUNTS(state, payload) {
		state.allActiveAccounts = [...state.allActiveAccounts, ...payload.results]
	},
	CLEAR_ALL_ACTIVE_ACCOUNTS(state, _) {
		state.allActiveAccounts = []
	},
	SET_ALL_COST_CENTER(state, payload) {
		state.allCostCenters = [...state.allCostCenters, ...payload.results]
	},
	CLEAR_ALL_COST_CENTER(state, _) {
		state.allCostCenters = []
	},
	UPDATE_ALL_NOTIFICATION(state, payload) {
		state.allNotification = payload
	},
}

