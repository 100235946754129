<template>
	<div class="">
		<div class="flex w-full" v-if="loadingAuth">
			<p class="h-8 w-16 bg-gray-300 animate-pulse rounded-full"></p>
			<p class="h-4 w-full bg-gray-300 animate-pulse mt-2 ml-2"></p>
		</div>

		<div v-else class="flex">
			<div
				class="flex justify-center align-items-center no-profile-img"
				:style="'background:' + rcolor"
				v-if="CURRENT_COMPANY"
			>
				<div v-if="CURRENT_COMPANY !== null">
					<span class="font-semibold">
						<span v-if="CURRENT_COMPANY"
							><img :src="CURRENT_COMPANY.company_logo" alt=""
						/></span>
					</span>
				</div>
			</div>
			<div class="widget-content p-0 mt-4 pl-1">
				<div class="widget-content-wrapper">
					<div class="widget-content-left">
						<div class="btn-group">
							<a
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								class="p-0 btn d-flex align-items-center"
							>
								<p
									class="bg-gray-300 w-40 h-4 rounded mr-2 animate-pulse"
									v-if="loadingCompany"
								></p>
								<div
									class="header-user-name mr-2 link-theme-color font-semibold"
									v-else
								>
									<span v-if="CURRENT_COMPANY">{{
										CURRENT_COMPANY.name
									}}</span>
								</div>
								<i
									class="icon ion-ios-arrow-down ml-2 opacity-8"
								></i>
							</a>
							<div
								tabindex="-1"
								role="menu"
								aria-hidden="true"
								class="rm-pointers tweak-style dropdown-menu-lg dropdown-menu dropdown-menu-right py-[0px]"
							>
								<div class="scroll-area-xs override-height">
									<div class="scrollbar-container ps mb-2">
										<ul
											class="nav flex-column divide-y-[2px]"
										>
											<li
												class="nav-item py-[7px] cursor-pointer"
												v-for="com in companyList"
												:key="com.id"
												@click="activateCompany(com)"
											>
												<a
													href="javascript:void(0)"
													class="text-sm link-theme-color text-decoration-none text-start w-full px-[3px]"
												>
													<span
														class="text-base icon-color mr-2 mt-1 mb-1 metismenu-icon"
													></span>
													{{ com.name }}
												</a>
											</li>
										</ul>
									</div>
								</div>
								<div class="nav-item nav-item-divider"></div>
								<ul class="nav flex-column">
									<li class="text-center nav-item">
										<a
											href="javascript:void(0);"
											@click="logoutUser"
											class="text-sm link-theme-color nav-link"
										>
											<span
												class="text-xl icon-color mr-2 mt-1 mb-1 iconly-brokenLogout"
											></span>
											{{ $t("logout") }}
										</a>
									</li>
									<li class="text-center nav-item">
										Version 1.2.0
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/plugins/i18n";

export default {
	name: "buyerCompanyList",
	components: {},
	data: function () {
		return {
			loadingCompany: false,
			loadingAuth: true,
			rcolor: window.localStorage.getItem("randomc") || "#000000",
			companyList: [],
		};
	},
	methods: {
		changeLocale(locale) {
			i18n.locale = locale;
			this.$services.moment.locale(locale);
			this.$store.commit("utility/CHANGE_LOCALE", locale);
		},
		getCompanyUser() {
			this.loadingAuth = true;
			this.$store
				.dispatch("customer/getConnectedShop", {
					data: "?is_connected=True&no_paging=1",
				})
				.then((res) => {
					this.companyList = res.data.suppliers;
					this.loadingAuth = false;
				})
				.catch((err) => {
					this.loadingAuth = false;
				});
		},
		activateCompany: function (company) {
			this.$store.commit("customer/UPDATE_CURRENT_COMPANY", {
				data: company,
			});
			let routeName = {
				name: "bloglist",
				params: { company_id: company.id },
			};
			setTimeout(() => {
				this.$router.replace(routeName);
			}, 500);
		},
		logoutUser() {
			this.changeLocale("se");
			this.$store.commit("auth/CLEAR_AUTH_CUS", null);
			window.Bus.$emit("sign-out");
		},
		getLogo: function (com) {
			let logo = this.companyList.filter((item) => item.id === com);
			return logo[0];
		},
	},
	computed: {
		...mapGetters({
			GET_COMPANY_USER: "portal/GET_COMPANY_USER",
			CURRENT_COMPANY: "customer/GET_CURRENT_COMPANY",
			GET_AUTH_USER: "portal/GET_AUTH_USER",
			GET_CUSTOMER_NAME: "customer/GET_CUSTOMER_NAME",
		}),
	},
	mounted() {
		this.getCompanyUser();
	},
};
</script>

<style lang="scss" scoped>
@import "./../assets/variables.scss";

// .app-theme-gray.fixed-header{
//     // .app-header__logo{
//     //     // background: #fff !important;
//     // }
// }
.h-5 {
	height: 3rem;
}

.logo-top {
	margin-top: -0.5rem;
}

.vertical-timeline::before {
	background: unset !important;
}

.scrollbar-container {
	.nav-item {
		.nav-link {
			.metismenu-icon {
				font-size: 2rem;
			}
		}
	}
}

.header-user-name {
	font-weight: 600;
	font-size: 1.2rem;
}

// .app-header__logo .logo-src {
//     // height: 46px !important;
//     // width: 99px !important;
//     // background-repeat: no-repeat;
//     // margin-left: 4rem;
// }

.nav-item.nav-item-divider {
	margin: unset !important;
}

.dropdown-menu-header {
	margin-bottom: unset !important;
}

.nav-item.nav-item-btn {
	padding: unset !important;
}

.override-height {
	height: unset !important;
}

.skeleton-com-text {
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #dddddd 8%, #dddddd 18%, #dddddd 33%);
	// background-size: 800px 104px;
	height: 15px;
	width: 150px;
	border-radius: 5px;
}

.skeleton-img {
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #dddddd 8%, #dddddd 18%, #dddddd 33%);
	// background-size: 800px 104px;
	// height: px;
	// width: 45px;
	// border-radius: 5px;
}

.rounded-circle-top:hover {
	background: #fff !important;
	z-index: 999;
}

.change-style {
	padding: 1px;
	border: 1px solid #fff;
}

.tweak-style {
	left: 40px !important;
}

.dropdown-menu.dropdown-menu-lg {
	min-width: 15rem !important;
}

.text-big {
	font-size: 1rem !important;
}

.nav-link {
	padding: 0.1rem 1rem !important;
}

.app-theme-gray .app-header {
	background: #ffff !important;
}

.ps__thumb-y {
	background: #ee4d81 !important;
}

.notify-height {
	/*height: 200px !important;*/
	transition: height 1s ease-out;
}

.hide-transition-height {
	height: 0px;
	opacity: 0;
	display: none;
}

.transition-div {
	transition: display 1s ease-out;
}

.dropdown-side-top::before {
	position: absolute;
	left: -1px;
	top: 50%;
	background: #dee2e6;
	width: 1px;
	height: 30px;
	margin-top: -15px;
	content: "";
}

.icon-wrapper img {
	margin: 0 auto;
	// font-size: 1.7rem;
	position: relative;
	z-index: 5;
}

.app-header__logo {
	width: 199px !important;
}
</style>
