import { render, staticRenderFns } from "./createArticleComponent.vue?vue&type=template&id=195dd186&scoped=true&"
import script from "./createArticleComponent.vue?vue&type=script&lang=js&"
export * from "./createArticleComponent.vue?vue&type=script&lang=js&"
import style0 from "./createArticleComponent.vue?vue&type=style&index=0&id=195dd186&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "195dd186",
  null
  
)

export default component.exports