const TYPES = {
	UPDATE_ARTICLES: "UPDATE_ARTICLES",
	CLEAR_CART: "CLEAR_CART",
	UPDATE_COMPANY_SETTINGS: "UPDATE_COMPANY_SETTINGS",
	UPDATE_USER_SETTINGS: "UPDATE_USER_SETTINGS",
	UPDATE_USER_SETTINGS_HIDE_FIELD_STATUS:
		"UPDATE_USER_SETTINGS_HIDE_FIELD_STATUS",
	ADD_CART_PRODUCT: "ADD_CART_PRODUCT",
	EMPTY_CART: "EMPTY_CART",
	UPDATE_CONNECTED_SHOP_LIST: "UPDATE_CONNECTED_SHOP_LIST",
	UPDATE_NON_CONNECTED_SHOP_LIST: "UPDATE_NON_CONNECTED_SHOP_LIST",
	FILTER_SEARCH: "FILTER_SEARCH",
	FILTER_ALL: "FILTER_ALL",
	SORT_PRICE: "SORT_PRICE",
	UPDATE_BILLING_INFO: "UPDATE_BILLING_INFO",
	UPDATE_COUNTRY: "UPDATE_COUNTRY",
	UPDATE_CUSTOMER_NAME: "UPDATE_CUSTOMER_NAME",
	UPDATE_ORDERS: "UPDATE_ORDERS",
	UPDATE_INVOICE: "UPDATE_INVOICE",
	UPDATE_PROVIDERS: "UPDATE_PROVIDERS",
	UPDATE_WEBSHOP_PRODUCT: "UPDATE_WEBSHOP_PRODUCT",
	UPDATE_CATEGORY: "UPDATE_CATEGORY",
	UPDATE_USER_SUBCRIPTION_TYPE: "UPDATE_USER_SUBCRIPTION_TYPE",
	UPDATE_USER_SUBCRIPTION: "UPDATE_USER_SUBCRIPTION",
	UPDATE_INTEGRATION_METRIC: "UPDATE_INTEGRATION_METRIC",
	UPDATED_CONNECTED_WEBSHOP: "UPDATED_CONNECTED_WEBSHOP",
	UPDATE_SHOP_USERS: "UPDATE_SHOP_USERS",
	UPDATE_APPLICATION_LOG: "UPDATE_APPLICATION_LOG",
	UPDATE_BLOG_CATEGORY: "UPDATE_BLOG_CATEGORY",
	UPDATE_BLOG_CATEGORY_COPY: "UPDATE_BLOG_CATEGORY_COPY",
	UPDATE_BLOG_POST: "UPDATE_BLOG_POST",
	UPDATE_ARCHIVE: "UPDATE_ARCHIVE",
	UPDATE_SINGLE_BLOG_POST: "UPDATE_SINGLE_BLOG_POST",
	UPDATE_FAQ_CATEGORY: "UPDATE_FAQ_CATEGORY",
	UPDATE_FAQZ: "UPDATE_FAQZ",
	UPDATE_CHECKOUT_CONFIG: "UPDATE_CHECKOUT_CONFIG",
	UPDATE_SINGLE_ARTICLE: "UPDATE_SINGLE_ARTICLE",
	UPDATE_ARTICLE_SPECIFICATION: "UPDATE_ARTICLE_SPECIFICATION",
	UPDATE_ARTICLE_PARAMS: "UPDATE_ARTICLE_PARAMS",
	UPDATE_CART_WITH_EXTRA_ITEM: "UPDATE_CART_WITH_EXTRA_ITEM",
	REMOVE_EXTRA_ITEM_FROM_CART: "REMOVE_EXTRA_ITEM_FROM_CART",

	UPDATE_DELIVERY_ADDRESS_DATA: "UPDATE_DELIVERY_ADDRESS_DATA",
	UPDATE_END_CUSTOMER: "UPDATE_END_CUSTOMER",
	UPDATE_USER_NOTIFICATION: "UPDATE_USER_NOTIFICATION",
	UPDATE_ALL_NOTIFICATION: "UPDATE_ALL_NOTIFICATION",
};

export default TYPES;
