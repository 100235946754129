<template>
	<div class="app-header header-shadow roboto-font">
		<div class="app-header__logo nav-header-side-customer"
			 :style="bgColor && bgColor ? 'background:'+bgColor+'!important' : 'background: #181C4C !important'">
			<div class="absolute inset-0" style="z-index:999" @click="hideWrapper" v-if="showWrapper"></div>
			<div class="logo-sr">
			</div>
			<div class="header__pane ml-auto">
			</div>
		</div>
		<div class="app-header__mobile-menu">
			<div>
				<button type="button" id="hamburger-btn" @click="toggleSidebar"
						class="hamburger hamburger--elastic mobile-toggle-nav">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
				</button>
			</div>
		</div>
		<div class="app-header__menu">
                <span>
                    <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
							@click="toggleNavTop">
                        <span class="btn-icon-wrapper">
                            <i class="fa fa-ellipsis-v fa-w-6"></i>
                        </span>
                    </button>
                </span>
		</div>
		<div class="app-header__content" id="div-open">
			<div class="app-header-left">
				<ul class="header-megamenu nav">
				</ul>
			</div>
			<div class="app-header-right">
				<div class="header-dots space-x-5">
					<div class="dropdown">
						<span class="p-0 mr-2 btn btn-link no-deco mt-6 lg:mt-15 hide-product-detail">
							<el-switch
								@change="onToggleHideField"
								v-model="hideFieldStatus"
								:inactive-text="$t('hideDetails')"
							>
						</el-switch>
							<el-tooltip class="item help-tooltip cursor-pointer position-absolut" effect="dark"
										:content="$t('hideDetailsHelpText')"
										placement="top-start">{{ $t('hideDetailsHelpText') }}
														<i class="help-tooltip cursor-pointer position-absolute"
														></i>
								</el-tooltip>
						</span>

					</div>
					<div class="absolute bg-red-30 inset-0 z-0" @click="notifyOpen = false" v-if="notifyOpen"></div>
					<div class="dropdown md:w-16">
						<button data-cy='notify-btn' type="button" @click="openNotifyDiv"
								class="p-0 mr- btn btn-link no-deco">
							<div class="flex align-items-center space-x-4">
                                    <span>
                                        <span class="icon-wrapper icon-wrapper-alt rounded-circle">
                                            <span class="icon-wrapper-bg border-2 border-gray-900"></span>
                                            <img src="./../assets/images/icons/notification.png" alt="">
                                            <span
												class="flex justify-center align-items-center text-xs icon text-white bg-yellow-500 rounded-full h-4 w-8 p-2 absolute ml-4 -mt-3 z-50">{{
													unReadNotificationCount
												}}</span>
                                        </span>
                                    </span>
							</div>
						</button>
						<div v-if="notifyOpen" data-cy='notify-opener'
							 class="w-60 width-div-mobile fixed md:absolute z-50 shadow bg-white border rounded mt-10 p-3 md:p-5 right-md">
							<div> <!--@scroll="handleScroll"-->
								<div class="flex justify-between">
									<div>
										<h5 class="text-sm menu-header-title" v-if="unReadNotificationCount === 0">
											{{ $t('noNotify') }}</h5>
										<h5 class="text-sm menu-header-title" v-else>{{ $t('notify') }}</h5>
									</div>
									<div class="flex">
											<span class="cursor-pointer font-semibold mr-1"
												  v-on:click="markAllNotification" v-if="unReadNotificationCount > 0">{{
													$t('markAll')
												}}</span>
										<span class="cursor-pointer font-semibold" v-on:click="openNotificationUrl">{{
												$t('viewAll')
											}}</span>
									</div>
								</div>
								<div v-if="GET_NOTIFICATION && unReadNotificationCount > 0" class="mt-3 overflow-y-scroll"
									 :class="GET_NOTIFICATION.data.length > 3 ? 'notify-height' : ''">
									<div class="" v-for="(notify, i) in GET_NOTIFICATION.data" :key="i">
										<NotificationHandler :notify="notify" :mode="`buyer`"/>
									</div>

								</div>
							</div>
						</div>
					</div>
					<div class="absolute bg-red-30 inset-0 z-0" @click="cartOpen = false" v-if="cartOpen"></div>
					<div class="dropdown">
						<button @click="openCartDiv" class="mb-2 mr-2 mt-3 btn-icon btn-icon-only btn btn-link btn-sm">
							<i class="text-2xl icon text-danger iconly-brokenBuy"> </i>
							<span class="badge badge-pill badge-warning" v-if="CART_PRODUCT !== null">{{
									CART_NUMBER
								}}</span>
						</button>
					</div>
					<div class="dropdown-side-top dropdown md:w-32">
						<locale-component class="pt-3"/>
					</div>
				</div>
				<div class="header-btn-lg pr-0">
					<buyer-company-list/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from "vue"
import {mapGetters} from 'vuex'
import i18n from '@/plugins/i18n'
import bus from '../bus'
import NotificationHandler from './../customComponent/NotificationHandler'
import CartProduct from './../customComponent/CartProduct'
import TYPES from "../store/modules/customer/types";
import {getUserSettingHideFieldStatus, getUserSetting} from "../services/storage-window";
// import {ElTooltip} from "element-ui"
//
// Vue.use(ElTooltip)
import LocaleComponent from "./LocaleComponent";
import buyerCompanyList from "./buyerCompanyList";

export default {
	name: 'navbar-dash',
	components: {
		CartProduct,
		NotificationHandler, LocaleComponent, buyerCompanyList
	},
	data() {
		return {
			baseURL: window.location.origin,
			user: {},
			unReadNotificationCount: 0,
			authUser: {},
			notification: [],
			ownerCompany: null,
			bgColor: null,
			company: [],
			companyList: [],
			notify_count: 0,
			unread_count: 0,
			rcolor: window.localStorage.getItem('randomc') || '#000000',
			unread_notify: false,
			loadingCompany: true,
			loadingAuth: true,
			loading: true,
			cartOpen: false,
			hideFieldStatus: false,
			showWrapper: false,
			notifyOpen: false,

		}
	},
	watch: {
		cartOpen(newVal, oldVal) {
			window.Bus.$emit('show-cover', this.cartOpen)
			// if (newVal === true) {
			// }
		},
		notifyOpen(newVal, oldVal) {
			window.Bus.$emit('show-cover', this.notifyOpen)
		}
	},
	computed: {
		...mapGetters({
			GET_COMPANY_USER: 'portal/GET_COMPANY_USER',
			GET_AUTH_USER: 'portal/GET_AUTH_USER',
			GET_LOCALE_LANG: 'utility/GET_LOCALE_LANG',
			CART_PRODUCT: 'customer/CART_PRODUCT',
			GET_CUSTOMER_NAME: 'customer/GET_CUSTOMER_NAME',
			CART_NUMBER: 'customer/CART_NUMBER',
			GET_NOTIFICATION: 'customer/GET_NOTIFICATION',
			HIDE_FIELD_STATUS: 'customer/GET_USER_SETTINGS_HIDE_FIELD_STATUS',
		})
	},
	mounted() {
		// this.getAuthUser()

		// this.getCompanyUser()
		this.getUserCompanyName()
		this.getConnectedShopList()
		this.getUserNotification()
		this.getUserUnreadCount()
		this.hideFieldStatus = getUserSettingHideFieldStatus() || false
		window.Bus.$on('close-cart', _ => {
			this.cartOpen = false
		})
		bus.$on('update-company-logo', () => {
			this.getCompanyUser()
		})
		window.Bus.$on('close-cart', e => {
			this.cartOpen = false
			this.notifyOpen = false
		})

		window.Bus.$on('reload-notification', e => {
			this.getUserNotification()
			this.getUserUnreadCount()
		})

		window.Bus.$on('show-wrapper', e => {
			if (e === true) {
				this.showWrapper = true
			} else {
				this.showWrapper = false
			}
		})

		if (this.GET_CUSTOMER_NAME.length > 0) {
			window.localStorage.setItem('comp_name', this.GET_CUSTOMER_NAME[0].customer_name)
		}

	},
	created() {
		this.getCompanyInfo()
	},
	methods: {
		getCompanyInfo() {
			if (process.env.VUE_APP_SYSTEM_MODE === 'whitelabel') {
				const payload = {
					id: process.env.VUE_APP_COMPANY_ID
				}
				this.$store.dispatch('customer/getCompanySettings', payload).then(resp => {
					if (resp.data.data.buyer_sidebar_menu_color) {
						this.bgColor = resp.data.data.buyer_sidebar_menu_color
					} else {
						this.bgColor = null
					}

				}).catch(err => {
					this.bgColor = null
				})
			}
		},
		onToggleHideField: function () {
			if (getUserSetting()) {
				this.$store.commit('customer/UPDATE_USER_SETTINGS_HIDE_FIELD_STATUS', this.hideFieldStatus)
				window.Bus.$emit('trigger-hidden-field-event', this.hideFieldStatus)
			} else {
				this.$store.dispatch('customer/getUserAccountSettings').then(resp => {
					this.$store.commit('customer/UPDATE_USER_SETTINGS_HIDE_FIELD_STATUS', this.hideFieldStatus)
					window.Bus.$emit('trigger-hidden-field-event', this.hideFieldStatus)
				}).catch(err => {
					this.hideFieldStatus = !this.hideFieldStatus
				})
			}


		},
		openNotificationUrl: function () {
			this.$router.replace({name: 'customer-notifications'})
		},
		getConnectedShopList() {
			this.loading = true
			this.$store.dispatch('customer/getConnectedShop', {data: `?is_connected=True`})
				.then(res => {
					this.loading = false
					this.companyList = res.data.suppliers
				})
				.catch(err => {
					this.loading = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_CUS', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
		openCartDiv() {
			if (['single-product', 'productarticles'].includes(this.$route.name) === false) {
				return false
			}
			window.Bus.$emit('open-cart-slider', {qty: this.CART_NUMBER})
			// window.Bus.$emit('show-cover', this.cartOpen)
		},
		markAllNotification: function () {
			this.$store.dispatch('customer/readAllNotification')
				.then(res => {
					window.Bus.$emit('reload-notification')
					this.$services.helpers.notification(this.$t('notificationNowMarkAsRead'), 'success', this)
				}).catch(err => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)

				}
			})
		},
		getUserUnreadCount() {
			this.unReadNotificationCount = 0
			this.$store.dispatch('customer/getAllNotification', {'data': `?status=unread`}).then(resp => {
				this.unReadNotificationCount = resp.data.total_count
				if (resp.data.total_count > 900) {
					this.unReadNotificationCount = '900+'
				} else {
					this.unReadNotificationCount = resp.data.total_count
				}
			})
		},
		openNotifyDiv() {
			this.notifyOpen = !this.notifyOpen
		},
		getAuthUser() {
			this.loadingAuth = true
			this.$store.dispatch('customer/getAuthUser')
				.then(res => {
					// console.log('AUTH;', res)
					this.loadingAuth = false
				}).catch(err => {
				this.loadingAuth = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getUserCompanyName() {
			this.$store.dispatch('customer/getUserCompanyName')
		},
		getCompanyUser() {
			this.$store.dispatch('customer/getCompanyUser')
				.then(_ => {
					this.loadingCompany = false
				}).catch(err => {
				this.loadingCompany = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		changeLocale(locale) {
			i18n.locale = locale
			this.$services.moment.locale(locale)
			this.$store.commit('utility/CHANGE_LOCALE', locale)
		},
		handleScroll(e) {
			if (parseInt(e.target.scrollTop) > 60) {
				document.getElementById('remove-div').classList.add('hide-transition-height')
				document.getElementById('extend-height').classList.add('notify-height')
			} else {
				document.getElementById('remove-div').classList.remove('hide-transition-height')
				document.getElementById('extend-height').classList.remove('notify-height')
			}
		},
		toggleSidebar() {
			document.getElementById('hamburger-btn').classList.toggle('is-active')
			document.getElementById('app-root-container').classList.toggle('sidebar-mobile-open')
		},
		getUserNotification() {
			this.$store.dispatch('customer/getUserNotification')
		},
		toggleNavTop() {
			// console.log('clicking')
			// console.log('what:', document.getElementById('div-open'))
			document.getElementById('div-open').classList.toggle('header-mobile-open')
		},
		hideWrapper() {
			window.Bus.$emit('hide-wrapper')
			this.showWrapper = false
		},
		logoutUser() {
			this.changeLocale('se')
			this.$store.commit('auth/CLEAR_AUTH_CUS', null)
			window.Bus.$emit('sign-out')
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../assets/variables.scss";

// .app-theme-gray.fixed-header{
//     // .app-header__logo{
//     //     // background: #fff !important;
//     // }
// }
.h-5 {
	height: 3rem;
}

.logo-top {
	margin-top: -0.5rem;
}

.vertical-timeline::before {
	background: unset !important;
}

.scrollbar-container {
	.nav-item {
		.nav-link {
			.metismenu-icon {
				font-size: 2rem;
			}
		}
	}
}

.header-user-name {
	font-weight: 600;
	font-size: 1.2rem;
}

// .app-header__logo .logo-src {
//     // height: 46px !important;
//     // width: 99px !important;
//     // background-repeat: no-repeat;
//     // margin-left: 4rem;
// }

.nav-item.nav-item-divider {
	margin: unset !important;
}

.dropdown-menu-header {
	margin-bottom: unset !important;
}

.nav-item.nav-item-btn {
	padding: unset !important;
}

.override-height {
	height: unset !important;
}

.skeleton-com-text {
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #dddddd 8%, #dddddd 18%, #dddddd 33%);
	// background-size: 800px 104px;
	height: 15px;
	width: 150px;
	border-radius: 5px;
}

.skeleton-img {
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #dddddd 8%, #dddddd 18%, #dddddd 33%);
	// background-size: 800px 104px;
	// height: px;
	// width: 45px;
	// border-radius: 5px;
}

.rounded-circle-top:hover {
	background: #fff !important;
	z-index: 999;
}

.change-style {
	padding: 1px;
	border: 1px solid #fff;
}

.tweak-style {
	left: 40px !important;
}

.dropdown-menu.dropdown-menu-lg {
	min-width: 15rem !important;
}

.text-big {
	font-size: 1rem !important;
}

.nav-link {
	padding: .1rem 1rem !important;
}

.app-theme-gray .app-header {
	background: #ffff !important;
}

.ps__thumb-y {
	background: #EE4D81 !important;
}

.notify-height {
	/*height: 200px !important;*/
	transition: height 1s ease-out;
}

.hide-transition-height {
	height: 0px;
	opacity: 0;
	display: none;
}

.transition-div {
	transition: display 1s ease-out;
}

.dropdown-side-top::before {
	position: absolute;
	left: -1px;
	top: 50%;
	background: #dee2e6;
	width: 1px;
	height: 30px;
	margin-top: -15px;
	content: '';
}

.icon-wrapper img {
	margin: 0 auto;
	// font-size: 1.7rem;
	position: relative;
	z-index: 5;
}

.app-header__logo {
	width: 199px !important;
}

</style>
