export const convertDiscountValueToPercentage = (
	discount_value,
	discount_type,
	sales_price
) => {
	if (discount_type === "PERCENT") {
		return discount_value;
	} else {
		const percent = (discount_value * 100) / sales_price;
		const percentRounded = Math.round(percent);
		if (!isFinite(percentRounded)) {
			return 0;
		}
		return percentRounded;
	}
};

export function get_vat_price(
	prices_given_ink_vat,
	vat,
	delivered_quantity,
	discountedPrice
) {
	if (prices_given_ink_vat) {
		return discountedPrice;
	} else {
		return (
			discountedPrice +
			get_vat_amount(
				prices_given_ink_vat,
				vat,
				delivered_quantity,
				discountedPrice
			) /
				delivered_quantity
		);
	}
}

export function get_vat_amount(
	prices_given_ink_vat,
	vat,
	delivered_quantity,
	discountedPrice
) {
	try {
		if (prices_given_ink_vat) {
			// Means that VAT is included in this product price
			// vat_amount = price_including_vat - price_excluding_vat
			// vat_amount = price_including_vat - (price_including_vat / 1 + vat_rate)

			let vat_rate = vat / 100;
			let vat_amount = discountedPrice - discountedPrice / (1 + vat_rate);
			return vat_amount * delivered_quantity;
		} else {
			// Means that VAT is not included in this product price
			let vat_rate = vat / 100;
			let vat_amount = discountedPrice * vat_rate;
			return vat_amount * delivered_quantity;
		}
	} catch (ex) {
		// default value is provided
		return 0;
	}
}

export function get_price_excluding_vat(discountedPrice) {
	if (obj.order.company.prices_given_ink_vat) {
		return discountedPrice - get_vat_amount(obj) / obj.delivered_quantity;
	} else {
		return discountedPrice;
	}
}
