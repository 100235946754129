import {
	getCartItem,
	getBuyerTourGuide,
	getUserSetting,
	getUserSettingHideFieldStatus, getUserId, getCurrentCompany

} from './../../../../services/storage-window'

export default {
	articles: {data: [], total_pages: 1},
	copyArticles: {data: []},
	extraCopy: [],
	billing: [],
	cartProduct: getCartItem() || [],
	connectedShopList: [],
	nonConnectedShopList: [],
	country: [],
	customerName: [],
	webshopProviders: [],
	webshopProduct: [],
	copyWebshopProduct: [],
	category: {},
	subscriptionType: '',
	userSubscription: {},
	integrationMetrics: [],
	connectedWebshop: [],
	shopUsers: [],
	copyShopUsers: [],
	applicationLog: [],
	copyApplicationLog: [],
	bankID: null,
	buyerTour: getBuyerTourGuide() || {},
	blogCategory: [],
	archives: [],
	blogPost: {data: [],total_pages: 1},
	singleBlogPost: [],
	cancelCheckout: false,
	faqPost: {},
	faqPostCategory: {},
	companySettings: {},
	singleArticle: {},
	companyCheckoutConfig: [],
	articleSpecification: [],
	articleParams: {
		limit: 20,
		pagenum: 1,
		searchname: ''
	},
	deliveryAddress: {results: [], total_pages: 1},
	END_CUSTOMER: {},
	userSettings: getUserSetting() || {},
	hideFieldStatus: getUserSettingHideFieldStatus() || false,
	currentCompany: getCurrentCompany() || {},
	userNotification: {data: []},
	allNotification: {data: [], total_pages: 1},
	userId: getUserId() || null
}
