<template>
	<div>
		<button type="button" data-toggle="dropdown"
				class="md:ml-4 flex w-full justify-between align-items-center space-x-5 p-0 mr-2 btn btn-lin">
			<div class="flex align-items-center space-x-4">
                                    <span>
                                        <img v-if="GET_LOCALE_LANG === 'en'"
											 :class="`h-${height} w-${width} rounded-full`"
											 src="./../assets/images/flags/gb.svg" alt="flags">
                                        <img v-else :class="`h-${height} w-${width} rounded-full`"
											 style="max-width:unset !important"
											 src="./../assets/images/flags/se.svg" alt="flags">
                                    </span>
				<span class="no-deco ml-2 tracking-wide font-semibold" v-if="showText === true">
                                        <span v-if="GET_LOCALE_LANG === 'en'">Engelska</span>
                                        <span v-else>Svenska</span>
                                    </span>
				<i class="icon ion-ios-arrow-down ml-2 opacity-8"></i>
			</div>

		</button>
		<div tabindex="-1" role="menu" aria-hidden="true"
			 class="rm-pointers dropdown-menu dropdown-menu-right w-24">
			<div class="dropdown-menu-header">
			</div>
			<button data-cy='btn-lang-eng' type="button" @click="changeLocale('en')" tabindex="0"
					class="dropdown-item flex align-items-center">
				<img class="h-7 w-7 mr-2" src="./../assets/images/flags/4x3/GB.svg" alt="flags">
				Engelska
			</button>
			<button data-cy='btn-lang-se' type="button" @click="changeLocale('se')" tabindex="0"
					class="dropdown-item flex align-items-center">
				<img class="h-7 w-7 mr-2" src="./../assets/images/flags/4x3/SE.svg" alt="flags">
				Svenska
			</button>
			<button data-cy='btn-lang-se' type="button" @click="changeLocale('se')" tabindex="0"
					class="dropdown-item flex align-items-center">
				<img class="h-7 w-7 mr-2" src="./../assets/images/flags/4x3/DK.svg" alt="flags">
				Danska
			</button>
			<button data-cy='btn-lang-se' type="button" @click="changeLocale('se')" tabindex="0"
					class="dropdown-item flex align-items-center">
				<img class="h-7 w-7 mr-2" src="./../assets/images/flags/4x3/NO.svg" alt="flags">
				Norska
			</button>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import i18n from '@/plugins/i18n'

export default {
	name: "LocaleComponent",
	props: {
		showText: {
			type: Boolean,
			default: true
		},
		width: {
			type: Number,
			default: 7
		},
		height: {
			type: Number,
			default: 7
		},
	},
	computed: {
		...mapGetters({
			GET_LOCALE_LANG: 'utility/GET_LOCALE_LANG',
		})
	},

	methods: {
		changeLocale(locale) {
			i18n.locale = locale
			this.$services.moment.locale(locale)
			this.$store.commit('utility/CHANGE_LOCALE', locale)
		},
	}
}
</script>

<style scoped>

</style>
