import HTTPEntry from "./../../../../services/vue-axios/entry";
import http from "./../../../../services/vue-axios/axios";
import downloadHTTP from "./../../../../services/vue-axios/download";
import downloadExcelHTTP from "../../../../services/vue-axios/download_excel";
import endpoints from "./../../../../services/endpoints";
import TYPES from "./../types";
import { getCompanyId } from "../../../../services/storage-window";

export default {
	async getAuthUser({ commit }, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.GET_AUTH_USER)
				.then((res) => {
					// console.log('auth user:', res)
					if (res.status === 200) {
						commit(TYPES.UPDATE_AUTH_USER, res.data);
						resolve("success");
					}
				})
				.catch((err) => {
					// console.log('lok:', err)
					reject(err);
				});
		});
	},
	async getCompanyUser({ commit }, _) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.COMPANY_ENDPOINT}`)
				.then((res) => {
					// console.log('com user:', res)
					if (res.status === 200) {
						commit(TYPES.UPDATE_USER_COMPANY, res.data);
						const payload = {
							menu: res.data.menu_color,
							link: res.data.link_color,
							button: res.data.button_color,
						};
						if (
							res.data.menu_color &&
							res.data.link_color &&
							res.data.button_color
						) {
							commit(TYPES.UPDATE_CUSTOMIZATION, payload);
						}
						resolve("success");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getCompanyDetails({ commit }, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.COMPANY_ENDPOINT)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_COMPANY_SETTING, res.data);
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getPriceList({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.GET_PRICE_LIST}`)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_PRICE_LIST, res.data.PriceLists);
						resolve(res);
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						commit("auth/CLEAR_AUTH_USER", null, { root: true });
					}
				});
		});
	},
	async updateCompanySettings({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(endpoints.COMPANY_ENDPOINT, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async uploadCompanyLogo({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.COMPANY_LOGO_UPLOAD, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve("success");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async uploadCompanyImages({ commit }, payload) {
		return new Promise((resolve, reject) => {
			let pageUrl =
				endpoints.SUBSCRIPTION_ENDPOINT + "supplier/upload_images/";
			http.put(pageUrl, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve("success");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getCostCenter({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.COST_CENTER_ENDPOINT}`)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_COST_CENTER, res.data);
						resolve(res);
					}
					// console.log('cost c:', res)
				})
				.catch((err) => {
					if (err.response.status === 401) {
						commit("auth/CLEAR_AUTH_USER", null, { root: true });
					}
				});
		});
	},
	async getCurrency({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.CURRENCY_ENDPOINT}`)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						commit("auth/CLEAR_AUTH_USER", null, { root: true });
					}
				});
		});
	},
	async getAllCostCenter({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.COST_CENTER_ENDPOINT}${payload.data}`)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.SET_ALL_COST_CENTER, res.data);
						resolve(res);
					}
					// console.log('cost c:', res)
				})
				.catch((err) => {
					if (err.response.status === 401) {
						commit("auth/CLEAR_AUTH_USER", null, { root: true });
					}
				});
		});
	},
	async getProject({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.PROJECT}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					if (err.response.status === 401) {
						commit("auth/CLEAR_AUTH_USER", null, { root: true });
					}
				});
		});
	},
	async getPaymentTerms({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.PAYMENT_TERMS}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					if (err.response.status === 401) {
						commit("auth/CLEAR_AUTH_USER", null, { root: true });
					}
				});
		});
	},
	async getCustomerCategory({ commit }, payload) {
		http.get(`${endpoints.CUSTOMER_CATEGORY}`)
			.then((res) => {
				if (res.status === 200) {
					commit(TYPES.UPDATE_CUSTOMER_CATEGORY, res.data);
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					commit("auth/CLEAR_AUTH_USER", null, { root: true });
				}
			});
	},
	async createCustomerCategory({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.CUSTOMER_CATEGORY, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve("success");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async setupInvitation({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.ENDUSER_EMPLOYEE_INVITE, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve("success");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getProductCategory({ commit }, _) {
		await http
			.get(endpoints.PRODUCT_CATEGORY_ENDPOINT)
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data);
					commit(TYPES.UPDATE_PRODUCT_CATEGORY, res.data);
				}
			})
			.catch((_) => {});
	},
	async addProductCategory({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.PRODUCT_CATEGORY_ENDPOINT, payload)
				.then((res) => {
					if (res.status === 200) {
						dispatch("getProductCategory")
							.then((_) => {
								resolve(res);
							})
							.catch((_) => {
								resolve(res);
							});
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateProductCategory({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			const id = payload.id;
			delete payload.id;
			http.put(endpoints.PRODUCT_CATEGORY_ENDPOINT + `${id}/`, payload)
				.then((res) => {
					dispatch("getProductCategory")
						.then((_) => {
							resolve(res);
						})
						.catch((_) => {
							resolve(res);
						});
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async deleteProductCategory({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			http.delete(endpoints.PRODUCT_CATEGORY_ENDPOINT + `${payload.id}/`)
				.then((res) => {
					if (res.status === 200 || res.status === 204) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getSubCategory({ commit }, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.SUB_CATEGORY_ENDPOINT)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getCategoryStructure({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.CATEGORY_STRUCTURE_ENDPOINT}${payload.data}`)
				.then((res) => {
					commit(TYPES.SET_CATEGORY_STRUCTURE, res.data);
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async saveCategoryStructure({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(`${endpoints.CATEGORY_STRUCTURE_ENDPOINT}`, payload.data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async assignSubCategoryToStructure({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(
				`${endpoints.CATEGORY_STRUCTURE_ENDPOINT}assign_subcategory/`,
				payload.data
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateCategoryStructure({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				`${endpoints.CATEGORY_STRUCTURE_ENDPOINT}${payload.id}/`,
				payload.data
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getArticleCategoryStructure({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.ARTICLES_ENDPOINT}/${payload.id}/categories/`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async assignArticleCategoryStructure({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				`${endpoints.ARTICLES_ENDPOINT}/${payload.id}/assign_categories/`,
				payload.data
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async bulkAssignArticleCategoryStructure({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				`${endpoints.ARTICLES_ENDPOINT}/bulk_assign_categories/`,
				payload.data
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async deleteCategoryStructure({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.delete(
				`${endpoints.CATEGORY_STRUCTURE_ENDPOINT}${payload.id}/`
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async deleteChildSubCategoryStructure({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				`${endpoints.CATEGORY_STRUCTURE_ENDPOINT}remove_subcategory/`,
				payload
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async addChildCategory({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.CATEGORY_STRUCTURE_ENDPOINT, payload)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async addSubCategory({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.SUB_CATEGORY_ENDPOINT, payload)
				.then((res) => {
					dispatch("getProductCategory")
						.then((_) => {
							resolve(res);
						})
						.catch((_) => {
							resolve(res);
						});
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateSubCategory({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			const id = payload.id;
			delete payload.id;
			http.put(endpoints.SUB_CATEGORY_ENDPOINT + `${id}/`, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async deleteSubCategory({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			http.delete(endpoints.SUB_CATEGORY_ENDPOINT + `${payload.id}/`)
				.then((res) => {
					if (res.status === 200 || res.status === 204) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async assignMassProductCategory({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.post(
				endpoints.ARTICLES_ENDPOINT + "/assign_category/",
				payload
			)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateUser({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(endpoints.SHOPOWNERS_EMPLOYEES + `${payload.id}/`, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve("success");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getApplicationLogs({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				payload.page ||
				endpoints.APPLICATION_LOGS + `?limit=${payload.limit}`;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_APPLICATION_LOG, res.data);
						resolve("success");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async deleteUser({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.delete(endpoints.SHOPOWNERS_EMPLOYEES + `${payload.id}/`)
				.then((res) => {
					if (res.status === 204) {
						resolve("success");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async deleteEndCustomer({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.delete(endpoints.COMPANY_ENDCUSTOMERS + `${payload.id}/`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async revokeEndCustomerInvite({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.delete(
				endpoints.COMPANY_ENDCUSTOMERS + `${payload.id}/revoke_invite/`
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getShopUsers({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.SHOPOWNERS_EMPLOYEES}${payload.data}`)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_SHOP_USERS, res.data);
					}
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async addUsers({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.ENDUSER_EMPLOYEE_INVITE, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve("success");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getCompanyEndCustomers({ commit }, payload) {
		return new Promise((resolve, reject) => {
			let relevant = "";
			if (payload.isRelevant === true) {
				relevant = "&is_relevant=true";
			}
			const invite = payload.status
				? `invite_status=${payload.status}&`
				: "";
			let pageUrl =
				payload.page ||
				endpoints.COMPANY_ENDCUSTOMERS +
					`?${invite}limit=${payload.limit}${relevant}`;
			if (payload.search) {
				pageUrl += `&search=${payload.search}`;
			}
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						if (payload.drawer) {
							commit(
								TYPES.UPDATE_SHOP_CUSTOMERS_DRAWER,
								res.data
							);
						} else if (payload.setting) {
							commit(
								TYPES.UPDATE_SHOP_CUSTOMER_DRAWER_SETTING,
								res.data
							);
						} else {
							commit(TYPES.UPDATE_SHOP_CUSTOMERS, res.data);
						}
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async filterCompanyEndCustomers({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.COMPANY_ENDCUSTOMERS}${payload.data}`)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getCompanyEndCustomer({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.COMPANY_ENDCUSTOMERS + `${payload.id}/`)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateCompanyEndCustomerCategory({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.put(endpoints.CUSTOMER_CATEGORY + `${payload.id}/`, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async deleteCompanyEndCustomerCategory({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.delete(endpoints.CUSTOMER_CATEGORY + `${payload.id}/`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateCustomer({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.put(endpoints.COMPANY_ENDCUSTOMERS + `${payload.id}/`, payload)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async createCustomer({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.COMPANY_ENDCUSTOMERS, payload)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async patchCustomer({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.patch(
				endpoints.COMPANY_ENDCUSTOMERS + `${payload.id}/`,
				payload
			)
				.then((res) => {
					if (res.status === 200) {
						resolve("success");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async putCustomer({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.put(endpoints.COMPANY_ENDCUSTOMERS + `${payload.id}/`, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve("success");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async userRequestOption({ _ }, payload) {
		return new Promise((resolve, reject) => {
			if (payload.status === "accept") {
				http.post(
					endpoints.COMPANY_ENDCUSTOMERS + "accept_request/",
					payload
				)
					.then((res) => {
						if (res.status === 200) {
							resolve("success");
						}
					})
					.catch((err) => {
						reject(err);
					});
			} else {
			}
		});
	},
	async setMultiRelevantCustomer({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.post(
				endpoints.COMPANY_ENDCUSTOMERS + "update_customers_relevancy/",
				payload
			)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getDashboardMetrics({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.DASHBOARD_METRICS}${payload.data}`)
				.then((res) => {
					// console.log('metrics:', res)
					if (res.status === 200) {
						commit(TYPES.UPDATE_DASHBOARD_METRICS, res.data.data);
						resolve(res.data);
						// this.dashboardMetrics = res.data.data
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						commit("auth/CLEAR_AUTH_USER", null, { root: true });
						reject(err);
					}
				});
		});
	},
	async filterGraphData({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.GRAPH_FILTER_ENDPOINT + `?${payload.query}`)
				.then((res) => {
					if (res.status === 200) {
						resolve(res.data);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getArticles({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.ARTICLES_ENDPOINT + payload.url;
			http.get(pageUrl)
				.then((res) => {
					commit(TYPES.UPDATE_ARTICLES, res.data);
					resolve(res);
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async getFilterArticles({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.ARTICLES_ENDPOINT + payload.url;
			http.get(pageUrl)
				.then((res) => {
					commit(TYPES.SET_ALL_ARTICLES, res.data);
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	getArticleAddon({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = `${endpoints.ARTICLES_ENDPOINT}/${payload.id}/addon/${payload.addon_type}`;
			http.get(pageUrl)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	getFullArticles({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.ARTICLES_ENDPOINT + payload.url;
			http.get(pageUrl)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async downloadArticles({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.ARTICLES_ENDPOINT + payload.url;
			downloadExcelHTTP
				.get(pageUrl)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async getSingleArticle({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.ARTICLES_ENDPOINT + payload.url;
			http.get(pageUrl)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async advanceFilterArticle({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.ARTICLES_ENDPOINT + `?${payload.query}`)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_SEARCH_ARTICLES, res.data);
						resolve("success");
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async getArticleSpreadSheet({ commit }, payload) {
		return new Promise((resolve, reject) => {
			let pageUrl = "";
			if (payload.page) {
				pageUrl = payload.page;
			} else {
				if (payload.limit) {
					pageUrl =
						endpoints.ARTICLES_ENDPOINT + `?limit=${payload.limit}`;
				} else {
					pageUrl = endpoints.ARTICLES_ENDPOINT;
				}
			}
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						// console.log('article:', res)
						commit(TYPES.UPDATE_ARTICLES_SPREADSHEET, res.data);
						resolve("success");
					}
				})
				.catch((_) => {
					// console.log(err)
					// reject(err)
				});
		});
	},
	async updateArticle({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				endpoints.ARTICLES_ENDPOINT + `/${payload.article_id}/`,
				payload
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async createArticle({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.ARTICLES_ENDPOINT + "/", payload)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async recommendArticle({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				endpoints.ARTICLES_ENDPOINT + `/${payload.id}/recommend/`,
				payload.data
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async accessoriesArticle({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				endpoints.ARTICLES_ENDPOINT + `/${payload.id}/accessories/`,
				payload.data
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async setArticleVariations({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				endpoints.ARTICLES_ENDPOINT + `/${payload.id}/variations/`,
				payload.data
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateArticlePdf({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				endpoints.ARTICLES_ENDPOINT + `/${payload.id}/`,
				payload.data
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async deleteArticle({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.delete(endpoints.ARTICLES_ENDPOINT + `/${payload.id}/`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async getCompanyOrders({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.COMPANY_ORDERS + payload.url;
			// if (payload.status) {
			//     pageUrl = payload.page || endpoints.COMPANY_ORDERS + `?is_invoice=False&tab_status=${payload.status}&limit=${payload.limit}`
			// } else {
			//     pageUrl = payload.page || endpoints.COMPANY_ORDERS + `?is_invoice=False&limit=${payload.limit}`
			// }
			http.get(pageUrl)
				.then((res) => {
					// console.log('orders:', res)
					if (res.status === 200) {
						commit(TYPES.UPDATE_ORDERS, res.data);
						resolve("success");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getCompanyInvoice({ commit }, payload) {
		return new Promise((resolve, reject) => {
			let pageUrl = "";
			if (payload.status) {
				pageUrl =
					payload.page ||
					endpoints.COMPANY_ORDERS +
						`?is_invoice=True&tab_status=${payload.status}&limit=${payload.limit}`;
			} else {
				pageUrl =
					payload.page ||
					endpoints.COMPANY_ORDERS +
						`?is_invoice=True&limit=${payload.limit}`;
			}
			if (payload.q) {
				pageUrl = pageUrl + `&q=${payload.q}`;
			}
			http.get(pageUrl)
				.then((res) => {
					// console.log('invoice:', res)
					if (res.status === 200) {
						commit(TYPES.UPDATE_INVOICE, res.data);
						resolve("success");
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async uploadArticlePictures({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.ARTICLE_IMAGES, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async uploadBulkArticleData({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.ARTICLES_ENDPOINT + "/bulk_upload/", payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async downloadFile({ rootState }, payload) {
		return new Promise((resolve, reject) => {
			const token = rootState.auth.token
				? rootState.auth.token
				: rootState.auth.cusToken;
			let URL = "";
			if (payload.key === "billing") {
				URL =
					endpoints.ORDER_DOWNLOAD +
					`?is_billing=True&document_number=${payload.doc}&token=${token}`;
			} else {
				if (payload.key === "invoice") {
					URL =
						endpoints.ORDER_DOWNLOAD +
						`?is_invoice=True&document_number=${payload.doc}&token=${token}&order_id=${payload.id.id}`;
				} else {
					URL =
						endpoints.ORDER_DOWNLOAD +
						`?is_invoice=False&document_number=${payload.doc}&token=${token}&order_id=${payload.id.id}`;
				}
			}

			downloadHTTP
				.get(URL)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async deleteImage({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.REMOVE_ARTICLE_IMAGE, payload)
				.then((res) => {
					// console.log(res.data)
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getUserSubscriptionType({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.get(endpoints.SUBSCRIPTION_ENDPOINT + `${payload.type}`)
				.then((res) => {
					if (res.status === 200) {
						commit(
							TYPES.UPDATE_USER_SUBCRIPTION_TYPE,
							res.data.data
						);
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getUserSubscription({ commit }, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.SUBSCRIPTION_ENDPOINT + "supplier/")
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_USER_SUBCRIPTION, res.data.data[0]);
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async addUserSubscription({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTPEntry.post(
				endpoints.SUBSCRIPTION_ENDPOINT + "supplier/",
				payload
			)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateUserSubscription({ dispatch }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				endpoints.SUBSCRIPTION_ENDPOINT +
					`supplier/${payload.current}/`,
				{
					subscription_type: {
						id: payload.sub,
					},
				}
			)
				.then((res) => {
					if (res.status === 200) {
						dispatch("getUserSubscription")
							.then((_) => {
								resolve(res);
							})
							.catch((_) => {
								resolve(res);
							});
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getShopBillingInfo({ commit }, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.SUBSCRIPTION_ENDPOINT + "billing/")
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_USER_BILLING, res.data);
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateBulkData({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(
				endpoints.ARTICLES_ENDPOINT + "/bulk_update/",
				payload.data
			)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_SELECTED_ARTICLE, payload.data);
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async updateRelevantBulkData({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(
				endpoints.ARTICLES_ENDPOINT + "/bulk_update/",
				payload.data
			)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async getAllEndCustomers({ commit }, _) {
		await http
			.get(`${endpoints.GET_ALL_CUSTOMERS}`)
			.then((res) => {
				if (res.status === 200) {
					commit(TYPES.UPDATE_ALL_CUSTOMERS, res.data.data);
				}
			})
			.catch((_) => {});
	},
	async getEndCustomers({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.GET_ALL_CUSTOMERS)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateCompanyPricelist({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(
				endpoints.ARTICLES_ENDPOINT + "/update_pricelist/",
				payload
			)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getUserNotification({ commit }, _) {
		http.get(`${endpoints.NOTIFICATION_ENDPOINT}`)
			.then((res) => {
				if (res.status === 200) {
					commit(TYPES.UPDATE_USER_NOTIFICATION, res.data);
				}
			})
			.catch((_) => {});
	},
	async getAllNotification({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.NOTIFICATION_ENDPOINT}${payload.data}`)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_ALL_NOTIFICATION, res.data);
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async readNotification({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				endpoints.NOTIFICATION_ENDPOINT + `${payload.id}/mark_as_read/`
			)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async readAllNotification({ _ }, payload) {
		return new Promise((resolve, reject) => {
			http.put(endpoints.NOTIFICATION_ENDPOINT + `mark_all_as_read/`)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getActiveAccounts({ commit }, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.ACTIVE_ACCOUNT_NUMBERS)
				.then((res) => {
					if (res.status === 200) {
						// console.log(res)
						commit(TYPES.UPDATE_ACTIVE_ACCOUNTS, res.data);
						resolve(res);
					}
				})
				.catch((_) => {});
		});
	},
	async getAllActiveAccounts({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.ACTIVE_ACCOUNT_NUMBERS + payload.url)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.SET_ALL_ACTIVE_ACCOUNTS, res.data);
						resolve(res);
					}
				})
				.catch((_) => {});
		});
	},
	async getFormFieldHelpText({ commit }, _) {
		http.get(endpoints.COMPANY_ENDPOINT + "_settings_help_text")
			.then((res) => {
				if (res.status === 200) {
					commit(TYPES.UPDATE_COMPANY_FORM_HELP_TEXT, res.data);
				}
			})
			.catch((_) => {});
	},
	async getBlogCategory({ commit }, _) {
		await http
			.get(endpoints.BLOG_ENDPOINT + "category/")
			.then((res) => {
				if (res.status === 200) {
					commit(TYPES.UPDATE_BLOG_CATEGORY, res.data);
				}
			})
			.catch((_) => {});
	},
	async getBlogTags({ commit }, _) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.BLOG_ENDPOINT + "tag/")
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_BLOG_TAG, res.data);
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async addBlogCategory({ commit, dispatch }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.BLOG_ENDPOINT + "category/", payload)
				.then((res) => {
					dispatch("getBlogCategory")
						.then((_) => {
							resolve(res);
						})
						.catch((_) => {
							resolve(res);
						});
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getBlog({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.BLOG_ENDPOINT + "post/", payload)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_BLOG_POST, res.data);
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getSingleBlog({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.BLOG_ENDPOINT + `post/${payload.id}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getBlogs({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.BLOG_ENDPOINT + "post/" + payload.url;
			http.get(pageUrl)
				.then((res) => {
					commit(TYPES.UPDATE_BLOG_POST, res.data);
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async addBlogTags({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.BLOG_ENDPOINT + "tag/bulk_add/", payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async addBlogPost({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.BLOG_ENDPOINT + "post/", payload)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateBlogPost({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const id = payload.get("id");
			delete payload.get("id");
			http.put(endpoints.BLOG_ENDPOINT + `post/${id}/`, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async deleteBlogPost(_, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.BLOG_ENDPOINT + "post/bulk_delete/", payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async deleteBlogCategory(_, payload) {
		return new Promise((resolve, reject) => {
			http.delete(endpoints.BLOG_ENDPOINT + `category/${payload.id}/`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateBlogCategory(_, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				endpoints.BLOG_ENDPOINT + `category/${payload.id}/`,
				payload
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getFaqz({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.FAQ_ENDPOINT + "post/" + payload.url;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_FAQZ, res.data);
						resolve(res);
					}
				})
				.catch((_) => {
					// console.log(err)
					// reject(err)
				});
		});
	},
	async getFaqCategory({ commit }, payload) {
		return new Promise((resolve, reject) => {
			let dt = payload.data !== null ? payload.data : "";
			const pageUrl = endpoints.FAQ_ENDPOINT + "category" + `${dt}`;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						commit(TYPES.UPDATE_FAQ_CATEGORY, res.data);
						resolve(res);
					}
				})
				.catch((_) => {
					// console.log(err)
					// reject(err)
				});
		});
	},
	async getCompanyFaqCategory({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.FAQ_ENDPOINT +
				`category/company_category/${payload.company_id}`;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((_) => {
					// console.log(err)
					// reject(err)
				});
		});
	},
	async getCompanyCheckoutConfiguration({ commit }, _) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.CHECKOUT_CUSTOMIZER;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async getArticleSpecification({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.PRODUCT_SPECIFICATION + payload.URL;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async saveArticleSpecification({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.PRODUCT_SPECIFICATION;
			http.post(pageUrl, payload)
				.then((res) => {
					if (res.status === 201) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async updateArticleSpecification({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.PRODUCT_SPECIFICATION + `${payload.id}/`;
			http.put(pageUrl, payload.data)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async updateNoteStatus({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.COMPANY_ORDERS +
				`/${payload.id}/update_order_note_status/`;
			http.put(pageUrl, payload.data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async deleteArticleSpecification({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.PRODUCT_SPECIFICATION +
				`${payload.id}/${payload.data}`;
			http.delete(pageUrl)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async getCompanyCheckoutConfigurationField({ commit }, _) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.CHECKOUT_CUSTOMIZER + "get_accounting_fields";
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async saveCompanyCheckoutConfiguration({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.CHECKOUT_CUSTOMIZER + "bulk_create_and_update/";
			http.post(pageUrl, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async updateCompanyCheckoutCustomerSpecific({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.CHECKOUT_CUSTOMIZER +
				`${payload.id}/update_customer_specific/`;
			http.put(pageUrl, payload.data)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async deleteCompanyCheckoutConfiguration({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.CHECKOUT_CUSTOMIZER + `${payload.id}/`;
			http.delete(pageUrl)
				.then((res) => {
					if (res.status === 204) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async updateOrders({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(`${endpoints.COMPANY_ORDERS}/${payload.id}/`, payload.data)
				.then((res) => {
					// console.log('orders:', res)
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async cancelOrder({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(
				`${endpoints.COMPANY_ORDERS}/${payload.id}/cancel/`,
				payload.data
			)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async createOrderInvoiceCopy({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(`${endpoints.COMPANY_ORDERS}/${payload.id}/copy/`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateOrderNote({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.COMPANY_ORDERS + payload.URL;
			http.put(pageUrl, payload.data)
				.then((res) => {
					// console.log('orders:', res)
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getCustomerDeliveryAddress({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.CUSTOMER_DELIVERY_ADDRESS + payload.URL;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async saveCustomerDeliveryAddress({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.CUSTOMER_DELIVERY_ADDRESS;
			http.post(pageUrl, payload)
				.then((res) => {
					if (res.status === 201) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async updateCustomerDeliveryAddress({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.CUSTOMER_DELIVERY_ADDRESS + `${payload.id}/`;
			http.put(pageUrl, payload.data)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async deleteCustomerDeliveryAddress({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.CUSTOMER_DELIVERY_ADDRESS + `${payload.id}/`;
			http.delete(pageUrl)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async getEmailConfig({ commit }) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.SUBSCRIPTION_ENDPOINT +
				`supplier/get_email_configuration/`;
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async getCompanySubscription({ commit }) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.SUBSCRIPTION_ENDPOINT +
				"supplier/company/subscription/";
			http.get(pageUrl)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async saveEmailConfig({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl =
				endpoints.SUBSCRIPTION_ENDPOINT +
				`supplier/update_email_configuration/`;
			http.put(pageUrl, payload)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async getMicrosoftUrl({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.MICROSOFT_OAUTH_URL;
			http.post(pageUrl, payload.data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async saveWhiteLabelFaq({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.FAQ_ENDPOINT + "post/";
			http.post(pageUrl, payload)
				.then((res) => {
					if (res.status === 201) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateWhiteLabelFaq({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.FAQ_ENDPOINT + `post/${payload.id}/`;
			http.put(pageUrl, payload.data)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async deleteWhiteLabelFaq({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.FAQ_ENDPOINT + `post/${payload.id}/`;
			http.delete(pageUrl)
				.then((res) => {
					if (res.status === 204) {
						resolve(res);
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async saveFaqCategory({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.FAQ_ENDPOINT + "category/";
			http.post(pageUrl, payload)
				.then((res) => {
					if (res.status === 201) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async updateFaqCategory({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.FAQ_ENDPOINT + `category/${payload.id}/`;
			http.put(pageUrl, payload.data)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async deleteWhitelabelFaqCategory({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const pageUrl = endpoints.FAQ_ENDPOINT + `category/${payload.id}/`;
			http.delete(pageUrl)
				.then((res) => {
					if (res.status === 204) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async updateRelevantBlogCustomer({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(
				endpoints.BLOG_ENDPOINT + `post/${payload.id}/customers/`,
				payload.data
			)
				.then((res) => {
					if (res.status === 200) {
						resolve(res);
					}
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async createOrder({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.COMPANY_ORDERS + `/create/`, payload.data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					// console.log(err)
					reject(err);
				});
		});
	},
	async deleteRow({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.delete(endpoints.COMPANY_ORDERS + `/order_rows/${payload.id}/`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async fetchUnits({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(endpoints.UNIT_TYPES + `${payload.data}`)
				.then((res) => {
					commit(TYPES.SET_UNIT_TYPES, res.data);
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async createUnit({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.post(endpoints.UNIT_TYPES, payload.data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async updateUnit({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.put(`${endpoints.UNIT_TYPES}${payload.id}/`, payload.data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async deleteUnit({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.delete(`${endpoints.UNIT_TYPES}${payload.id}/`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getVideos({ commit }, payload) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.ADMIN_RESOURCE_VIDEOS}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	async getIntegrationProvider({ commit }) {
		return new Promise((resolve, reject) => {
			http.get(`${endpoints.INTEGRATION_PROVIDER_ENDPOINT}`)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
};
