<template>
	<div class="p-3 mt-3">
		<div class="container flex justify-between mb-1  p-3 bg-white section-container">
			<div class="w-full">
				<div class="w-full border-bottom h-7 drawer" v-on:click="openCustomerInfo">
					<i class="lnr-chevron-down font-semibold ml-1 icon-size"
					   v-if="customerInfo === false"></i>
					<i class="lnr-chevron-up font-semibold ml-1 icon-size" v-else></i><span
					class="ml-2 font-semibold text-base">{{ $t('customerInformation') }}</span></div>
				<div class="row form-row mt-2" v-if="customerInfo === true">
					<div class="col-md-4">
						<div class="form-group">
							<label class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('customerName')
								}}</label>
							<div>
								<input type="text" v-model="customerObj.customer_name"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
									   required>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('customerNumber')
								}}</label>
							<div>
								<input type="text" v-model="customerObj.customer_number"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
								>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('organizationNumber')
								}}</label>
							<div>
								<input type="text" v-model="customerObj.organization_number"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
								>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('customerEmail')
								}}</label>
							<div>
								<input type="email" v-model="customerObj.email"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('phoneNumber')
								}}</label>
							<div>
								<input type="email" v-model="customerObj.phone_number"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('customerAddress')
								}}</label>
							<div>
								<input type="email" v-model="customerObj.address"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
						</div>
					</div>
				</div>
				<div class="w-full border-bottom h-7 drawer" v-on:click="openDeliveryInfo">
					<i class="lnr-chevron-down font-semibold ml-1 icon-size"
					   v-if="deliveryInfo === false"></i>
					<i class="lnr-chevron-up font-semibold ml-1 icon-size" v-else></i><span
					class="ml-2 font-semibold text-base">{{ $t('deliveryInformation') }}</span></div>
				<div class="row form-row mt-2" v-if="deliveryInfo === true">
					<div class="col-md-4">
						<div class="form-group">
							<label class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('deliveryName')
								}}</label>
							<div>
								<input type="email" v-model="customerObj.delivery_name"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('deliveryAddress')
								}} 1</label>
							<div>
								<input type="email" v-model="customerObj.delivery_address1"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('deliveryZipCode')
								}}</label>
							<div>
								<input type="email" v-model="customerObj.delivery_zip_code"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('deliveryCity')
								}}</label>
							<div>
								<input type="email" v-model="customerObj.delivery_city"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('country')
								}}</label>
							<div>
								<el-select v-model="customerObj.delivery_country" filterable :placeholder="$t('select')"
										   class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full">
									<el-option
										v-for="(item,key) in countries"
										v-bind:key="key"
										:clearable="false"
										:label="item.name"
										:value="item.key">
									</el-option>
								</el-select>
								<!--                <select v-model="customerObj.delivery_country"-->
								<!--                        class=" flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">-->
								<!--                  <option v-for="(item,key) in countries" :key="key" :value="item.key">-->
								<!--                    {{ item.name }}-->
								<!--                  </option>-->
								<!--                </select>-->
								<!--									<input type="text" v-model="deliveryAddress.country"-->
								<!--										   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"-->
								<!--										   required>-->
							</div>
						</div>
					</div>
				</div>
				<div class="w-full border-bottom h-7 drawer" v-on:click="openOtherInfo">
					<i class="lnr-chevron-down font-semibold ml-1 icon-size"
					   v-if="otherInfo === false"></i>
					<i class="lnr-chevron-up font-semibold ml-1 icon-size" v-else></i><span
					class="ml-2 font-semibold text-base">{{ $t('otherInformation') }}</span></div>
				<div class="row form-row mt-2" v-if="otherInfo === true">
					<div class="col-md-4">
						<div class="form-group">
							<div style='z-index:99999' class="w-4/5 absolute bg-white shadow border rounded mt-16"
								 v-if="modal && filteredAccount.length > 0">
								<ul>
									<li class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
										v-for="(item, i) in filteredAccount.slice(0, 5)" :key="i"
										@click="setAccount(item)">{{ item.number + ' (' + item.description + ')' }}
									</li>
								</ul>
							</div>
							<label class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('accountNumber')
								}}</label>
							<div>
								<!-- <input v-if="customerObj.category && customerObj.category.account_number" type="text" v-model="accountNumber" autocomplete="off" @focus="modal = true" @input="filterAccnt" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full" required> -->
								<input type="text" v-model="customerObj.account_number" autocomplete="off"
									   @focus="modal = true"
									   @input="filterAccnt"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('category')
								}}</label>
							<div>
								<el-select v-model="customerObj.category" filterable :placeholder="$t('select')"
										   class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full">
									<el-option
										v-for="cate in GET_CUSTOMER_CATEGORY.results"
										v-bind:key="cate.id"
										:clearable="false"
										:label="cate.name"
										:value="cate.id">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-group">
							<label class="col-form-label font-bold whitespace-nowrap text-xs">{{
									$t('maxCreditLimit')
								}}</label>
							<div>
								<input type="num" min="0" v-model="customerObj.max_credit_limit"
									   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group mb-0 mt-1">
							<label
								class="label-w-input col-form-label font-light whitespace-nowrap ">
								<input data-cy='render-only-customer-specific-article' type="checkbox"
									   id="render-only-customer-specific-article"
									   v-model="customerObj.render_only_customer_specific_articles"
									   class="checkbox-height  h-4 w-4">
								<span class="ml-2">{{ $t('renderOnlyCustomerSpecificArticle') }} <span
									class="help-tooltip cursor-pointer"
									:title="$t('renderOnlyCustomerSpecificArticle')"></span></span>
							</label>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group mb-0 mt-1">
							<label
								class="label-w-input col-form-label font-light whitespace-nowrap ">
								<input data-cy='render-only-customer-specific-article' type="checkbox"
									   id="render-only-customer-specific-categories"
									   v-model="customerObj.render_only_customer_specific_categories"
									   class="checkbox-height  h-4 w-4">
								<span class="ml-2">{{ $t('renderOnlyCustomerSpecificCategories') }} <span
									class="help-tooltip cursor-pointer"
									:title="$t('renderOnlyCustomerSpecificCategories')"></span></span>
							</label>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group mb-0">
							<label
								class="flex label-w-input col-form-label font-light whitespace-nowrap ">
								<input data-cy='render-only-customer-specific-categories' type="checkbox"
									   v-model="customerObj.customer_specific_reference_field"
									   class="checkbox-height  h-4 w-4">
								<p class="ml-2">{{ $t('renderOnlyCustomerSpecificReferenceField') }} </p>
								<span
									class="help-tooltip cursor-pointer"
									:title="$t('renderOnlyCustomerSpecificReferenceField')"></span>
							</label>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group mb-0">
							<label
								class="flex label-w-input col-form-label font-light whitespace-nowrap ">
								<input data-cy='self_allowed_create_delivery_addresses' type="checkbox"
									   v-model="customerObj.self_allowed_create_delivery_addresses"
									   class="checkbox-height  h-4 w-4">
								<p class="ml-2">{{ $t('self_allowed_create_delivery_addresses') }} </p>
								<span
									class="help-tooltip cursor-pointer"
									:title="$t('self_allowed_create_delivery_addresses')"></span>
							</label>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group mb-0">
							<label
								class="flex label-w-input col-form-label font-light whitespace-nowrap ">
								<input data-cy='self_allowed_create_delivery_addresses' type="checkbox"
									   v-model="customerObj.apply_customer_specific_user_profiles"
									   class="checkbox-height  h-4 w-4">
								<p class="ml-2">{{ $t('apply_customer_specific_user_profiles') }} </p>
								<span
									class="help-tooltip cursor-pointer"
									:title="$t('apply_customer_specific_user_profiles')"></span>
							</label>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group mb-0">
							<label
								class="flex label-w-input col-form-label font-light whitespace-nowrap ">
								<input data-cy='self_allowed_create_delivery_addresses' type="checkbox"
									   v-model="customerObj.apply_customer_currency_on_orders"
									   class="checkbox-height  h-4 w-4">
								<p class="ml-2">{{ $t('apply_customer_currency_on_orders') }} </p>
								<span
									class="help-tooltip cursor-pointer"
									:title="$t('apply_customer_currency_on_orders')"></span>
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<div class="form-group mb-0 w-full mt-10">
							<button :disabled="updating" v-on:click="onUpdateCustomer"
									:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
									:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-blue-900'"
									class="flex justify-center align-items-center space-x-4 w-25 mx-auto bg-blue-900 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full">
                                    <span v-if="updating" class="flex align-items-center space-x-3">
                                        <svg class="animate-spin mr-2 h-5 w-5 text-white"
											 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('updating') }}
                                    </span>
								<span v-else>{{ $t('update') }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "editCustomer",
	data: function () {
		return {
			updating: false,
			customerInfo: false,
			deliveryInfo: false,
			otherInfo: false,
			modal: false,
			accountNumber: '',
			countries: [],
			customerObj: {
				customer_name: '',
				customer_number: '',
				organization_number: '',
				email: '',
				address: '',
				category: '',
				phone_number: '',
				max_credit_limit: '',
				account_number: '',
				delivery_name: '',
				delivery_address1: '',
				delivery_zip_code: '',
				delivery_country: '',
				delivery_city: '',
				render_only_customer_specific_articles: false,
				render_only_customer_specific_categories: false,
				customer_specific_reference_field: false,
				self_allowed_create_delivery_addresses: false,
				apply_customer_specific_user_profiles: false,
				apply_customer_currency_on_orders: false,
			},
			filteredAccount: [],
			costCenterNumber: '',
			boolField: ['render_only_customer_specific_articles',
				'render_only_customer_specific_categories',
				'customer_specific_reference_field',
				'self_allowed_create_delivery_addresses',
				'apply_customer_specific_user_profiles',
				'apply_customer_currency_on_orders']
		}
	},
	props: {
		customer: {
			type: Object,
			required: true
		}
	},
	methods: {
		openCustomerInfo: function () {
			this.customerInfo = !this.customerInfo
		},
		openDeliveryInfo: function () {
			this.deliveryInfo = !this.deliveryInfo
		},
		openOtherInfo: function () {
			this.otherInfo = !this.otherInfo
		},
		onUpdateCustomer: function () {
			this.updating = true
			let payload = {}
			Object.keys(this.customerObj).map(k => {
				if (this.boolField.includes(k) === true) {
					payload[k] = this.customerObj[k]
				} else {
					if (this.customerObj[k]) {
						payload[k] = this.customerObj[k]
					}
				}
			})
			payload['category'] = {'id': this.customerObj.category}
			if (this.costCenterNumber !== '') {
				const costIndex = this.GET_COST_CENTER.results.findIndex(item => item.id === this.costCenterNumber)
				const costCenterInfo = this.GET_COST_CENTER.results[costIndex]
				payload.category.cost_center = {
					id: this.costCenterNumber,
					code: costCenterInfo.code,
					description: costCenterInfo.description
				}
			}
			payload['id'] = this.customer.id
			this.$store.dispatch('portal/updateCustomer', payload)
				.then(res => {
					this.updating = false
					this.$services.helpers.notification(this.$t('updateSuccess'), 'success', this)
					window.Bus.$emit('reset-customers-settings')
				})
				.catch(err => {
					this.updating = false
					window.Bus.$emit('output-error', err)
				})
		},
		filterAccnt() {
			this.filteredAccount = this.GET_ACTIVE_ACCOUNTS.results.filter(account => {
				return String(account.number).includes(this.accountNumber)
			})
		},
		setAccount(account) {
			this.customerObj.account_number = account.number
			this.selectAccount = account.number
			this.modal = false
		},
		getCostCenter() {
			this.$store.dispatch('portal/getCostCenter')
		},
	},
	computed: {
		...mapGetters({
			GET_COST_CENTER: 'portal/GET_COST_CENTER',
			GET_CUSTOMER_CATEGORY: 'portal/GET_CUSTOMER_CATEGORY',
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
			GET_ACTIVE_ACCOUNTS: 'portal/GET_ACTIVE_ACCOUNTS',
		})
	},
	mounted() {
		this.getCostCenter()
		this.countries = this.$services.helpers.getCountryList()
		Object.keys(this.customerObj).map(k => {
			if (['category'].includes(k) === false && this.customer.hasOwnProperty(k)) {
				this.customerObj[k] = this.customer[k]
			}
		})
		if (this.customer.category) {
			if (this.customer.hasOwnProperty('category') && this.customer.category.hasOwnProperty('id')) {
				this.customerObj.category = this.customer.category.id
			}
		}

	}
}
</script>


<style scoped>
.mb-20px {
	margin-bottom: 20px !important;
}

.drawer {
	padding-top: 10px;
	padding-bottom: 32px;
}

</style>
