<template>
	<div class="p-4">
		<div class="cart-product-listing-loop roboto-font">
			<div
				class="flex flex-col align-items-center cart-product-listing px-1.5 py-2"
				v-for="(product, key) in CART_PRODUCT"
				:key="product.id"
			>
				<div class="cp-thumb w-3/12 mr-2" v-if="product.images[0]">
					<img
						class="w-24"
						:src="product.images[0].image"
						alt=""
						v-if="product.images.length > 0"
					/>
				</div>
				<div class="flex flex-col cp-summary w-full">
					<div class="flex flex-col">
						<span class="cp-desc">{{ product.description }}</span
						><br />
					</div>

					<div class="flex justify-between">
						<span v-if="!product.has_discount">
							<span class="cp-price font-semibold relative top-2">
								{{
									computePrice(
										product.sales_price,
										product.qty
									)
								}}&nbsp;kr/styck</span
							>
						</span>
						<del v-else>
							<span class="cp-price relative top-2">
								{{
									computePrice(
										product.sales_price,
										product.qty
									)
								}}&nbsp;kr/styck</span
							>
						</del>
						<div class="flex">
							<input-number
								@onchange="handleChange($event, product)"
								:num="product.qty"
								:mode="`cart`"
								:key="`${key}_${new Date().getSeconds()}`"
							/>
							<span
								@click="RemoveFromCart(product)"
								class="flex justify-center align-items-center ml-2 h-5 w-5 rounded-full bg-red-600 cursor-pointer relative top-2"
							>
								<img
									src="./../assets/images/icons/delete.png"
									class="h-3 w-3"
									alt="delete"
								/>
							</span>
						</div>
					</div>
				</div>

				<div
					class="flex w-full justify-start"
					v-if="
						product?.has_discount &&
						Number(product?.discount_value) > 0
					"
				>
					<span>{{ $t("discounted") }}:</span>
					<span class="font-black text-red-500"
						>&nbsp; &nbsp;
						{{
							convertDiscountValueToPercentage(
								product?.discount_value,
								product?.discount_type,
								product?.sales_price
							)
						}}&nbsp;%</span
					>
				</div>
				<div
					v-if="product.has_discount"
					class="flex mb-2 w-full justify-start"
				>
					<span class="cp-price font-semibold relative top-2">
						{{
							computePrice(product.discounted_price, product.qty)
						}}&nbsp;kr/styck</span
					>
				</div>
			</div>
		</div>
		<div
			class="flex flex-col cart-total-cta w-full px-1.5 py-3"
			v-if="CART_PRODUCT.length > 0"
		>
			<!-- {{ CART_AMOUNT }} -->
			<div class="flex justify-between w-full mb-2">
				<span>{{ $t("subTotal") }}:</span>
				<span class="font-black">{{ CART_AMOUNT }}</span>
			</div>

			<div class="w-full flex justify-center">
				<button
					type="button"
					@click="checkoutHandler"
					class="flex justify-center no-deco w-full py-2 bg-black text-white rounded-md"
				>
					{{ $t("checkout") }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
// import bus from '../bus'
import inputNumber from "../views/customer/component/inputNumber";
import { convertDiscountValueToPercentage } from "../utils/utils";

export default {
	name: "notification-handler",
	components: {
		inputNumber,
	},
	props: {
		// notify : {
		//     type : Object,
		//     required : false
		// }
	},
	data() {
		return {
			imageBaseServerURL: "https://api.b2bportalen.se/media/",
			open: false,
			num: 0,
			convertDiscountValueToPercentage,
		};
	},
	computed: {
		...mapGetters({
			CART_PRODUCT: "customer/CART_PRODUCT",
			CART_AMOUNT: "customer/CART_AMOUNT",
			GET_COMPANY: "customer/GET_COMPANY_SETTINGS",
		}),
	},
	methods: {
		applyDiscount(price, discount, isPercent = true) {
			if (isPercent) {
				let discountAmount = price * (discount / 100);
				let discountedPrice = price - discountAmount;
				return discountedPrice.toFixed(2);
			} else {
				return Number(price) - Number(discount);
			}
		},
		computePrice(price, qty) {
			return this.$services.helpers.formatPrice(
				parseFloat(price) * parseInt(qty)
			);
		},
		handleChange(e, product) {
			if (e > 0) {
				this.$store.commit("customer/UPDATE_CART_QUANTITY", {
					product,
					qty: e,
				});
				window.Bus.$emit("message", {
					data: `${e} ${this.$t("itemAdd")}`,
				});
			} else {
				this.$store.commit("customer/REMOVE_CART_PRODUCT", {
					product,
					remove: true,
					qty: e,
				});
				window.Bus.$emit("message", { data: this.$t("itemRemove") });
			}
		},
		RemoveFromCart(product) {
			this.$store.commit("customer/REMOVE_CART_PRODUCT", {
				product: product,
			});
		},
		hasDiscount(product) {
			let currentDate = moment().format("YYYY-MM-DD");
			if (
				product.scheduled_campaign_start_date !== null &&
				product.scheduled_campaign_end_date !== null &&
				product.scheduled_campaign_start_date !== "" &&
				product.scheduled_campaign_end_date !== ""
			) {
				let startDate = moment(
					product.scheduled_campaign_start_date
				).format("YYYY-MM-DD");
				let endDate = moment(
					product.scheduled_campaign_end_date
				).format("YYYY-MM-DD");
				return !!(currentDate >= startDate && currentDate <= endDate);
			} else {
				return false;
			}
		},
		checkoutHandler: function () {
			window.Bus.$emit("close-cart-slider");
			this.$router.replace({
				name: "checkout",
				params: {
					company_id: this.$route.params.company_id,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.el-input-number--mini {
	width: 95px !important;
}
</style>
