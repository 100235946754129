import {
	getCustomization,
	getShopOwnerTourGuide,
	getCompanyCustomizationInstance, getSellerSetting
} from './../../../../services/storage-window'

export default {
	authUser: {},
	companyUser: {},
	companySetting: getSellerSetting() !== null ? getSellerSetting() : {
		name: '',
		organization_number: '',
		address: '',
		city: '',
		phone_number: '',
		post_invoice: false,
		shop_url: '',
		contact_email: '',
		id: '',
		zip_code: '',
		invoice_email: '',
		brand_name: '',
		price_list: {
			id: ''
		},
	},
	companySubscriptionSettings: getCompanyCustomizationInstance() !== null ? getCompanyCustomizationInstance() : {
		seller_login_description: '',
		full_logo: '',
		half_logo: '',
		brand_name: '',
		link_color: '',
		menu_color: null,
		button_color: null,
	},
	CompanyFormHelpText: [],
	customization: getCustomization() !== null ? getCustomization() : {},
	articles: [],
	copyArticles: [],
	finalArticleCopy: [],
	articleSpreadSheet: [],
	copyArticleSpreadSheet: [],
	orders: [],
	copyOrders: [],
	invoice: [],
	copyInvoice: [],
	productCategory: [],
	billing: [],
	subscriptionType: '',
	userSubscription: {},
	shopBilling: [],
	copyShopBilling: [],
	priceList: [],
	activeAccounts: [],
	userNotification: {data: []},
	allNotification: {data: [], total_pages: 1},
	userDetails: {},
	costCenter: [],
	customerCategory: [],
	shopUsers: [],
	copyShopUsers: [],
	shopCustomers: [],
	copyShopCustomersDrawer: [],
	shopCustomersDrawerSetting: [],
	copyShopCustomersDrawerSetting: [],
	shopCustomersDrawer: [],
	allCustomers: [],
	copyShopCustomers: [],
	invitedCustomers: [],
	dashboardMetrics: {
		average_order_amount: 0,
		total_invited_customers: 0,
		total_orders_this_month: 0,
		total_orders_today: 0,
		total_customer_visit_today: 0,
		total_sales_this_month: 0,
		total_published_articles: 0,
		best_x_selling_articles: [],
		customer_with_high_purchase: [],
		graph_revenue_monthly_data: {}
	},
	applicationLog: [],
	copyApplicationLog: [],
	shopOwnerTour: getShopOwnerTourGuide() || {},
	blogCategory: [],
	blogTag: [],
	blogPost: {},
	blogPostCopy: {},
	singleBlogPost: {},
	blogPreview: {},
	blogImage: '',
	faqPost: {},
	faqPostCategory: {},
	companyCheckoutCustomizer: {},
	articleCategoryStructure: {results: [], count: 0, total_pages: 1, page: 1},
	articleFilter: [],
	customerFilter: [],
	orderFilter: [],
	productPriceFilter: [],
	unitTypeObj: {results: [], count: 0, total_pages: 1, page: 1},
	allArticles: [],
	allActiveAccounts: [],
	allCostCenters: [],
}
