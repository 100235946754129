<template>
	<div id="app">
		<router-view/>
		<notifications group="b2b" :max="2" classes="custom-notification"/>
	</div>
</template>

<script>
import {getLocale, saveLocale} from './services/storage-window'
import i18n from '@/plugins/i18n'

export default {
	name: 'root',
	mounted() {
		document.addEventListener('contextmenu', function (e) {
			e.preventDefault()
		})

		if (!getLocale()) {
			// window.localStorage.setItem('xlocale', 'se')
			saveLocale('se')
			// this.$services.moment.locale('se')
		} else {
			i18n.locale = getLocale()
			// this.$services.moment.locale(i18n.locale)
		}
		window.Bus.$on('on-notify', e => {
			if (e.status === 'success') {
				this.$notify({
					group: 'b2b',
					// title: 'Success',
					text: e.message,
					type: 'success',
					duration: 4000,
				});
			} else if (e.status === 'error') {
				this.$notify({
					group: 'b2b',
					// title: 'Error',
					text: e.message,
					type: 'error',
					duration: 4000,
				});
			} else {
				this.$notify({
					group: 'b2b',
					// title: 'Processing',
					text: e.message,
					type: 'processing',
					duration: 4000,
				});
			}
		})
		// ERROR MESSAGE FORMATTING
		window.Bus.$on('output-error', err => {
			if (err.response.status === 401) {
				this.$store.commit('auth/CLEAR_AUTH_USER', null)
				window.Bus.$emit('sign-out')
			} else if (err.response.status === 500) {
				this.$services.helpers.notification(this.$t('serverError'), 'error', this)
			} else {
				if (err.response.data.hasOwnProperty('message') && err.response.data.hasOwnProperty('message_swe')) {
					if (this.GET_LOCALE_LANG === 'se' && err.response.data.hasOwnProperty('message_swe')) {
						this.$services.helpers.notification(err.response.data.message_swe, 'error', this)
					} else {
						this.$services.helpers.notification(err.response.data.message, 'error', this)
					}
				} else if (err.response.data.hasOwnProperty('message')) {
					this.$services.helpers.notification(err.response.data.message, 'error', this)
				} else if (err.response.data.hasOwnProperty('error')) {
					this.$services.helpers.notification(err.response.data.error, 'error', this)
				} else if (err.response.data.hasOwnProperty('errors')) {
					if (typeof err.response.data.errors === 'object') {
						for (const [key, value] of Object.entries(err.response.data.errors)) {
							if (Array.isArray(value)) {
								this.$services.helpers.notification(`${this.errKeyName[key]} - ${value}`, 'error', this)
							} else {
								this.$services.helpers.notification(`${this.errKeyName[key]} - ${value}`, 'error', this)

							}
							return false
						}
					}
				} else {
					this.$services.helpers.notification(err.response.data.message, 'error', this)
				}
			}
		})
	},
	created() {
		this.reloadCompanyImage()
		window.Bus.$on('update-company-images', () => {
			this.reloadCompanyImage()
		})
	},
	methods: {
		reloadCompanyImage: function () {
			let linkList = document.getElementsByTagName('link')
			if (process.env.VUE_APP_SYSTEM_MODE === 'whitelabel') {
				this.$store.dispatch('auth/getCompanySetup', {company_id: process.env.VUE_APP_COMPANY_ID}).then(resp => {
					window.document.title = resp.data.data.brand_name

					if (linkList.length > 0) {
						if (resp.data.data.fav_icon_logo) {
							linkList[0].setAttribute('href', resp.data.data.fav_icon_logo)
						}
					}
					this.$store.commit('portal/UPDATE_COMPANY_SUBSCRIPTION_SETTING', resp.data.data)

				})
			} else {
				this.$store.commit('portal/UPDATE_COMPANY_SUBSCRIPTION_SETTING', null)
				if (linkList.length > 0) {
					linkList[0].setAttribute('href', process.env.VUE_APP_LOGO_URL)
				}
				window.document.title = 'B2B Portal'
			}
		}
	}
}
</script>

<style lang='scss'>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');
@import "./assets/variables.scss";
@import "./assets/Styles/style.css";
@import "./assets/custom.scss";

.vue-notification-group {
	right: 15px !important;
}

.vue-notification-wrapper {
	display: block;
	overflow: hidden;
	width: 100%;
	margin: 0;
	padding: 0;
	border-radius: 10px;
	border-bottom-left-radius: 10px !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;

}

.custom-notification {
	margin: 0 5px 5px;
	width: 350px;
	font-size: 12px;
	top: 8px;
	position: relative;
	border-left: none;
	color: #ffffff;
	border-radius: 0.375rem;
	padding: 21px;
	//box-shadow: 0 1px 3px rgb(0 0 0 / 18%) !important;

	.notification-title {
		font-weight: bold;
	}

	.notification-content {
		font-weight: 700;
		//font-size: 13px;
	}

	&.warn {
		background: #ffb648;
		font-size: 0.8rem !important;
		line-height: 1rem !important;
		color: #ffffff;
	}

	&.processing {
		background: #60a5fa;
		font-size: 0.8rem !important;
		line-height: 1rem !important;
		color: #ffffff;
	}

	&.error {
		background: #dc2626;
		font-size: 0.8rem !important;
		color: #ffffff;
		line-height: 1rem !important;
	}

	&.success {
		background: #33cc85;
		font-size: 0.8rem !important;
		line-height: 1rem !important;
		color: #ffffff;
	}
}

html {
	// font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
	font-family: 'DM Sans' !important;
	overflow-x: hidden !important;
}

::-webkit-scrollbar {
	width: 3px;
}

::-webkit-scrollbar-track {
	background: $form-border;
}

::-webkit-scrollbar-thumb {
	background-color: $theme-primary-color !important; /* color of the scroll thumb */
	border-radius: 10px; /* roundness of the scroll thumb */
}

* {
	scrollbar-width: thin;
	scrollbar-color: $theme-primary-color $form-border; /* scroll thumb and track */
}

@media only screen and (min-width: 993px) {
	.hide-on-large-only {
		display: none !important;
	}
	.closed-sidebar .app-sidebar {
		width: 80px !important;
		min-width: 80px !important;
	}
	.custom-mt {
		margin-top: 5rem !important;
	}
	// label + input.checkbox-height[type='checkbox'] {
	//     /* float: left !important; */tab-btn-btn
	//     // margin-left: -9rem;
	// }
	.min-h-reg {
		min-height: 300px !important;
	}
	.min-height-product {
		min-height: 330px !important;
	}
	.r-min-h {
		min-height: 160px !important;
	}
	.nav-header-side-customer {
		background: $production-theme !important;
	}
	.fixed-sidebar .single-main .app-main__outer {
		padding-left: 200px !important;
	}
	.right-md {
		right: 5px;
	}
	.width-div-mobile {
		min-width: 20rem !important;
	}
	.ql-container.ql-snow {
		height: 80% !important;
	}
	.h-custom-screen {
		height: 60vh;
	}
}

@media only screen and (max-width: 992px) {
	.tab-btn {
		height: 30px !important;
		width: 75px !important;
		// left: 851px !important;
		// top: 99px !important;
		font-size: 11px !important;
		color: #fff;
		background: #181C4C !important;
	}
	.right-md {
		right: 50px;
	}
	.width-div-mobile {
		min-width: 21rem !important;
	}
}

.dm-sans-font {
	font-family: 'DM Sans' !important;
	font-weight: 300;
}

.el-drawer__header {
	font-family: 'DM Sans' !important;
	font-size: 1.2rem !important;
	color: #181C4C !important;
}

.no-deco {
	text-decoration: none !important;
}

.closed-sidebar:not(.closed-sidebar-mobile) .app-header .app-header__logo {
	width: 80px !important;
}

.closed-sidebar.fixed-sidebar .single-main .app-main__outer {
	padding-left: unset !important;
}

.closed-sidebar.fixed-sidebar .accountant-main .app-main__outer {
	padding-left: unset !important;
}

//.app-header__logo {
//	width: 199px !important;
//}

.font-bold {
	font-weight: 800;
}

.fixed-sidebar .accountant-main .app-main__outer {
	padding-left: unset !important;
}

// .fixed-sidebar .single-main .app-main__outer{
//     padding-left: 200px !important;
// }

/* .single-main {

} */
.closed-sidebar.fixed-sidebar .app-main__outer {
	padding-left: 80px !important;
}

.shopowner-app-sidebar {
	width: 201px !important;
	min-width: 201px !important;
}

.buyer-app-sidebar {
	width: 200px !important;
	min-width: 200px !important;
}

.header-user-name {
	font-size: 0.8rem !important;
}

.card-text {
	font-size: 1.1rem !important;
}

.heading-text {
	font-size: 1.2rem !important;
}

.vertical-nav-menu i.metismenu-icon {
	font-size: 1rem !important;
}

.small-icon {
	font-size: 1rem !important;
}

.min-h {
	min-height: 200px !important;
}

.cursor {
	cursor: pointer;
}

@keyframes placeHolderShimmer {
	0% {
		background-position: -468px 0
	}
	100% {
		background-position: 468px 0
	}
}

.form-input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	/* background-color: #fff; */
	/* border-color: #e2e8f0; */
	border-width: 1px;
	border-radius: 0.25rem;
	padding-top: 0.5rem;
	padding-right: 0.75rem;
	padding-bottom: 0.5rem;
	padding-left: 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
}

.form-input::-webkit-input-placeholder {
	color: #a0aec0;
	opacity: 1;
}

.form-input::-moz-placeholder {
	color: #a0aec0;
	opacity: 1;
}

.form-input:-ms-input-placeholder {
	color: #a0aec0;
	opacity: 1;
}

.form-input::-ms-input-placeholder {
	color: #a0aec0;
	opacity: 1;
}

.form-input::placeholder {
	color: #a0aec0;
	opacity: 1;
}

.form-input:focus {
	outline: none;
	/* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
	/* border-color: #63b3ed; */
}

.checkbox-height {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	display: inline-block;
	vertical-align: middle;
	background-origin: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	flex-shrink: 0;
	height: 1em;
	width: 1em;
	color: $theme-primary-color;
	background-color: #fff;
	border-color: #DFE0EB;
	border-width: 1px;
	border-radius: 0.25rem;
}

.checkbox-height:checked {
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
	border-color: transparent;
	background-color: currentColor;
	background-size: 100% 100%;
	background-position: center;
	background-repeat: no-repeat;
}

@media not print {
	.checkbox-height::-ms-check {
		border-width: 1px;
		color: transparent;
		background: inherit;
		border-color: inherit;
		border-radius: inherit;
	}
}

.checkbox-height:focus {
	outline: none;
	box-shadow: 0 0 0 3px $theme-primary-color;
	border-color: $form-border;
}


.input-shadow-box {
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.form-bg {
	background: #F1F6F6 !important;
}

.b-radius {
	border-radius: 8px !important;
}

.form-select {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	background-repeat: no-repeat;
	background-color: #fff;
	border-color: #DFE0EB;
	border-width: 1px;
	border-radius: 0.25rem;
	padding-top: 0.5rem;
	padding-right: 2.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	background-position: right 0.5rem center;
	background-size: 1.5em 1.5em;
}

.override-height {
	height: unset !important;
}

.color-custom-head {
	color: #344258 !important;
	/* color: #0c1427 !important; */
}

.btn-custom {
	background-color: #181C4C !important;
	border-color: #181C4C !important;
	color: #FFFFFF;
}

.btn-theme-custom {
	background-color: #0DB23C !important;
	border-color: #0DB23C !important;
	color: #FFFFFF;
}

.btn-custom-green {
	background-color: #0DB23C !important;
	border-color: #0DB23C !important;
	color: #FFFFFF;
}

.btn-theme-amazon {
	background-color: #db880c !important;
	border-color: #db880c !important;
	color: #FFFFFF;
}

.link-color {
	color: #E8B105;
}

.pagination {
	li.active {
		a {
			background-color: #181C4C !important;
			border-color: #181C4C !important;
		}
	}
}

// pagination li.active a
.link-theme-color {
	color: #1E2C41 !important;
}

.help-color {
	color: #546295 !important;
}

.no-de-hover:hover {
	color: #181C4C !important;
}

.label-context {
	white-space: nowrap;
	font-size: 20px !important;
	color: #000;
	font-weight: 800;
}

.label-context-reg {
	white-space: nowrap;
	font-size: 18px !important;
	color: #000;
	font-weight: 800;
}

.house-theme-head {
	color: #7E3469 !important;
}

.house-theme-sm {
	color: #71429F !important;
}

.house-theme-sm-head {
	color: #3A71C2 !important;
}

.unset-margin {
	margin-bottom: unset !important;
}

.no-profile-img {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	font-size: 15px;
	// width: 44px;
	// height: 44px;
	border-radius: 50%;
	// background: #512DA8;
	// font-size: 22px;
	color: #fff;
	text-align: center;
	// line-height: 150px;
	margin: 20px 0;

	p {
		margin-top: -52px;
		color: #fff;
		font-size: 17px;
	}
}

#sticky-text-area::-webkit-scrollbar {
	width: 0px; /* Remove scrollbar space */
	background: transparent; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
#sticky-text-area::-webkit-scrollbar-thumb {
	background: transparent;
}

#sticky-text-area::-webkit-scrollbar-track {
	background: #ddd;
	//   background: transparent;
}

.bg-stick {
	background: #EFEFD6;
}

.el-loading-mask {
	.el-loading-spinner {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.icon-color {
	color: #200E32 !important;
}

.table-head-color {
	color: #3A71C2 !important;
}

.text-sm-app {
	font-size: 0.775rem;
	line-height: 1.25rem;
}

.notification-container {
	bottom: unset !important;
	top: 60px !important;
	z-index: 99999999 !important;
}

.notify-me:first-child {
	margin-bottom: 0;
	width: 300px;
}

.notify-me {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	bottom: 2rem;
	right: 2rem;
	z-index: 9999;
	margin-bottom: 1.5rem;
}

.notification {
	background-color: #f5f5f5;
	border-radius: 3px;
	padding: 1.25rem 2.5rem 1.25rem 1.5rem;
	position: relative;
}

.notification.is-danger {
	background-color: #e62968;
	color: #fff;
}

.notification.is-success {
	background-color: #33cc85;
	color: #fff;
}

.notification.is-info {
	background-color: #33a3e8;
	color: #fff;
}

.flag.small {
	height: 15px !important;
	width: 15px !important;
	background-size: 15px 15px;
}

.lg-icon {
	font-size: 1.5rem !important;
}

.c-pad-setting {
	padding: 0.175rem .75rem !important;
}

// .company-select{
//     .el-input{
//         // .el-input__inner{
//         //     // height: !important;
//         // }
//     }
// }
.app-input.el-input__inner {
	height: unset !important;
}

.el-range-editor.el-input__inner {
	padding: 0px 10px !important;
}

.el-range-editor.el-input__inner {
	padding: 0px 10px !important;
	// padding-top: unset !important;
}

.text-xs-custom {
	font-size: 10px !important;
	line-height: 1rem !important;
}

.text-xs {
	font-size: 0.8rem !important;
	line-height: 1rem !important;
}

.text-xs-menu {
	font-size: 0.6rem !important;
}

.pagination li a {
	padding: .2rem .75rem !important;
}

.header-dots .icon-wrapper-alt {
	height: 35px !important;
	width: 35px !important;
}

.dropdown-menu-header .dropdown-menu-header-inner {
	padding: 0.2rem .5rem !important;
}

.app-theme-gray .app-header .app-header__logo {
	border-right: unset !important;
}

.c-pad-setting {
	border: 1px solid $form-border !important;
}

.c-pad-setting:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.checkbox-height:focus {
	box-shadow: unset !important;
	color: $theme-primary-color !important;
	border: 1px solid $theme-primary-color !important;
}

.o-pad {
	padding: unset !important;
}

.note.neutral {
	border-radius: 10px !important;
	background-color: #EFEFD6 !important;
	box-shadow: 0px 7px 30px rgba(34, 45, 57, 0.16) !important;
}

.ql-container.ql-snow {
	height: 200px;
}

// Tooltip
.tooltip-settings {
	position: relative;
	display: inline-block;
	//   border-bottom: 1px dotted black;
}

.tooltip-settings .tooltiptext {
	visibility: hidden;
	width: 360px;
	background-color: #ffff;
	//   background-color: #1E2C41;
	color: #1E2C41;
	//   text-align: center;
	border-radius: 6px;
	padding: 12px;

	/* Position the tooltip */
	position: absolute;
	z-index: 1;
}

.tooltip-settings:hover .tooltiptext {
	visibility: visible;
}

.vertical-timeline::before {
	background: #181C4C !important;
}

.dropdown-menu.dropdown-menu-xl {
	min-width: 34rem !important;
}

.el-slider__runway, .el-slider__bar {
	height: 3px !important;
}

// Theme css
.tab-btn {
	height: 39px;
	width: 161px;
	left: 851px;
	top: 99px;
	// border-radius: 13px;
	color: #fff;
	background: #181C4C !important;
}

.customer-carousel .flickity-prev-next-button .flickity-button-icon {
	width: 24% !important;
}

.customer-carousel .flickity-prev-next-button {
	width: 29px !important;
	height: 28px !important;
}

.el-message-box__wrapper {
	z-index: 9999999999 !important;
}

.bg-table-yellow {
	background-color: #F4C54B;
}

.bg-table-red {
	background-color: #f44b5f;
}

.bg-table-green {
	background-color: #51D184;
}

.bg-table-blue {
	background-color: #7BD4F0;
}

// .tabledata{
//     .handsontable .ht_master thead{
//         visibility: visible !important;
//     }
//     .handsontable .make-me-red{
//         color: #e62968 !important;
//         font-weight: bold;
//     }
//     .handsontable .make-me-blue{
//         color: #092C5C !important;
//         font-weight: bold;
//     }
//     .handsontable th,
//     .handsontable td{
//         color: #000 !important;
//         font-size: 12px;
//     }
// }
// .handsontable .ht_master thead, .handsontable .ht_master tr th, .handsontable .ht_clone_left thead{
//     visibility: visible !important;
// }
.disabled\:opacity-50:disabled {
	opacity: .5;
	cursor: not-allowed;
}

.el-select-dropdown {
	z-index: 99999999 !important;
}

.el-select-group__title {
	font-size: 15px !important;
}

.app-header.header-shadow {
	box-shadow: unset !important;
}

.el-color-picker {
	.el-color-picker__trigger {
		height: 31px !important;
		width: 31px !important;
	}
}

.theme-header-size {
	font-size: 25px !important;
}

.dashboard-head-size {
	font-size: 20px !important;
}

.theme-main-size {
	font-size: 30px !important;
}

.pagination-block ul.pagination li a {
	display: flex;
	justify-content: center;
}

.el-carousel__container {
	height: 90px !important;
}

.v-step {
	background: #2d8a91 !important;
	z-index: 999999 !important;
}

.quillWrapper .ql-snow.ql-toolbar {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.blog-date-publish {
	.el-input__inner {
		width: 140px !important;
	}
}

.single-product-img {
	.el-carousel__container {
		height: 20rem !important;
	}
}

.category-drawer-style {
	.el-drawer__body {
		overflow: hidden !important;
	}
}
</style>
